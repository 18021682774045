import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Anchor, Group, MantineTheme, Text, useMantineTheme } from '@mantine/core';
import { Dropzone, DropzoneStatus } from '@mantine/dropzone';
import { useEffect, useMemo, useRef, useState } from 'react';
import { X } from 'tabler-icons-react';
import submissionApi from '../../../pages/submission/submission.model';
import { FormFieldProps, InputFieldType } from './types';
import { CheckIfSims, getMimeType } from '../../../utils/helper';
import { ALLOWED_FILE_TYPES, MAX_FILE_SIZE } from './constants';
import localStorage from '../../../helpers/local-storage';

export const dropzoneChildren = (status: DropzoneStatus, theme: MantineTheme, props: InputFieldType) => (
  <Group position="center">
    <FontAwesomeIcon icon={faPlus} />
    <div>
      <Text size="sm" color="dimmed" inline>
        {props.description}
      </Text>
    </div>
  </Group>
);

export default function InputFile(props: FormFieldProps) {
  const isAdmin = localStorage.IsAdmin();
  const disable = useMemo(() => {
    if (props.columnConfig?.dependencyColumn) {
      const columnName = props.columnConfig.dependencyColumn.columnName;
      const tableName = props.columnConfig.dependencyColumn.tableName;
      const value = (props.form.values[tableName][columnName] ?? '').replace(/[{}]/g, '');

      if (value !== props.columnConfig?.dependencyColumn.value) {
        return false;
      }
    }
    return true;
  }, [props.form.values, props.columnConfig?.dependencyColumn]);

  const [inputProps, setinputProps] = useState(`${props.tableName}.${props.columnName}`);
  const dropZoneRef = useRef<HTMLDivElement | null>(null);
  const [file, setFile] = useState<any>();

  const theme = useMantineTheme();
  const handleFiles = async (files: File[]) => {
    let url = window.URL || window.webkitURL;
    setFile({ file: files[0], url: url.createObjectURL(files[0]), name: files[0].name });

    try {
      const id = await submissionApi.UploadFile(files[0]);
      props.form.setFieldValue(inputProps, id);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!disable) {
      setFile(null);
    }
  }, [disable]);

  useEffect(() => {
    if (props.index !== undefined) setinputProps(`${props.tableName}.${props.index}.${props.columnName}`);
  }, [props.columnName, props.index, props.tableName]);

  useEffect(() => {
    const getFile = async () => {
      try {
        const fileId = props.form.values[props.tableName][props.columnName];
        if (fileId) {
          const arr = new Uint8Array(fileId.data);

          const mimeType = getMimeType(arr);

          const blob = new Blob([arr], { type: mimeType });
          const url = window.URL.createObjectURL(blob);
          const description = props.description || 'Check Last File Uploaded?';
          setFile({ url, name: description });
          try {
            const file = new File([blob], description);
            const id = await submissionApi.UploadFile(file);
            props.form.setFieldValue(inputProps, id);
          } catch (error) {
            console.log(error);
          }
        }
      } catch (error) {
        console.log(error);
      }
    };
    getFile();
  }, []);

  const editableByAll = useMemo(() => {
    if (!props?.columnConfig) {
      return true;
    }

    if (props.columnConfig.hasOwnProperty('editableByAll')) {
      const editableByAll = props.columnConfig?.editableByAll;
      if (!editableByAll) {
        return isAdmin && !editableByAll;
      }
      return editableByAll;
    }
    return true;
  }, [props.columnConfig, isAdmin]);

  const rootStyles = {
    maxWidth: '20rem',
    margin: '0.5rem',
  };

  const ifSims = CheckIfSims();

  if (ifSims) {
    Object.assign(rootStyles, { marginLeft: 'auto' });
  }

  const inputValueProps = { ...props.form.getInputProps(inputProps) };
  const hasInitialValue = !!inputValueProps?.value;

  const disabledForm = useMemo(() => {
    if (['ed_rgm_doc', 'fd_rgm_doc'].includes(props.columnName)) {
      return !editableByAll;
    }
    return props.nonEditableFilledForm && hasInitialValue;
  }, [props.nonEditableFilledForm, hasInitialValue, editableByAll, props.columnName]);

  return disable ? (
    <Group spacing={'lg'}>
      {ifSims && (
        <div
          style={{ fontSize: '14px', paddingLeft: '0.5rem', flexGrow: 1 }}
          className="mantine-10efxhd mantine-TextInput-label"
        >
          {props.description}
        </div>
      )}
      <Dropzone
        className="mantine-10efxhd mantine-TextInput-label"
        ref={dropZoneRef}
        name={props.columnName}
        onDrop={handleFiles}
        disabled={disabledForm}
        onReject={files => console.log('rejected files', files)}
        maxSize={MAX_FILE_SIZE}
        accept={ALLOWED_FILE_TYPES}
        styles={{
          root: rootStyles,
        }}
        sx={{
          display: file ? 'none' : 'block',
        }}
      >
        {status => dropzoneChildren(status, theme, props)}
      </Dropzone>
      {file && (
        <Group>
          <Anchor
            sx={{ cursor: 'pointer' }}
            href={file.url}
            target="_blank"
            className="mantine-10efxhd mantine-TextInput-label"
          >
            {file.name}
          </Anchor>
          <X
            size={20}
            strokeWidth={2}
            onClick={() => {
              setFile(null);
            }}
            style={{ cursor: 'pointer' }}
          />
        </Group>
      )}
    </Group>
  ) : (
    <></>
  );
}
