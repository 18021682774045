import { ActionIcon } from '@mantine/core';
import { FC, useEffect, useState } from 'react';
import { ArrowUp } from 'tabler-icons-react';

export const ScrollToTop: FC = () => {
  const [visible, setVisible] = useState(false);
  const scrollUp = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    const handlScroll = () => {
      if (window.scrollY > 100) {
        setVisible(true);
      } else {
        setVisible(false);
      }
    };
    window.addEventListener('scroll', handlScroll);

    return () => {
      window.removeEventListener('scroll', handlScroll);
    };
  }, []);

  return (
    <ActionIcon
      sx={{
        position: 'fixed',
        bottom: '20px',
        right: '30px',
        border: 'none',
        outline: 'none',
        cursor: 'pointer',
        display: visible ? 'block' : 'none',
      }}
      size="lg"
      variant="filled"
      onClick={() => scrollUp()}
    >
      <ArrowUp size="2rem" />
    </ActionIcon>
  );
};
