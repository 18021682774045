import { Loader as MantineLoader, Container, Center } from "@mantine/core";

const Loader = () => (
   <Center>
      <Container fluid>
         <MantineLoader
            className="your-class-name"
            style={{ margin: 10 }}
            sx={(theme) => ({
               backgroundColor: theme.colors.gray[0],
               "&:hover": {
                  backgroundColor: theme.colors.gray[1],
               },
            })}
         />
         <div>loading...</div>
      </Container>
   </Center>
);

export default Loader;
