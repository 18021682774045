import { useMutation } from 'react-query';
import { IErrorObject } from '../../services/types';
import ResetPasswordModel from './model/reset-password.model';
import { useForm, yupResolver } from '@mantine/form';
import { IResetPasswordFormProps } from './types';
import { resetPasswordSchema } from './schema';

export function useResetPasswordForm() {
  return useForm({
    initialValues: {
      email: '',
    },
    validate: yupResolver(resetPasswordSchema),
  });
}

export const useProcessPasswordResetLink = () => {
  return useMutation<void, IErrorObject, IResetPasswordFormProps, void>(
    async ({ email }: IResetPasswordFormProps) => {
      return await ResetPasswordModel.GetPasswordResetLink({ email });
    },
    {}
  );
};
