import CoreConfig from "../../../config/core";
import CoreService from "../../../services/core";
import CatchError from "../../../services/error";
import { ICoreServiceProps } from "../../../services/types";
import { IRegisterFormProps } from "../types";

class RegisterModel extends CoreService {
   constructor(path: string, config: ICoreServiceProps) {
      super(path, config);
      this.path = path;
   }

   async Register(payload: IRegisterFormProps) {
      try {
         await this.axios.post(`${this.path}`, payload);
      } catch (e: any) {
         throw CatchError.Negotiate(e);
      }
   }

   async Verify({ code }: { code: string }) {
      try {
         await this.axios.post(`${this.path}/verify`, { code });
      } catch (e: any) {
         throw CatchError.Negotiate(e);
      }
   }

   async Resend({ email }: { email: string }) {
      try {
         await this.axios.put(`${this.path}/users/code`, { email });
      } catch (e: any) {
         throw CatchError.Negotiate(e);
      }
   }
}

export default new RegisterModel(`/customer-users`, CoreConfig);
