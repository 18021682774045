import { useMutation, useQuery } from 'react-query';
import AdminSubmissionsModel from './AdminSubmissions.model';

const useGetSubmissions = (page: number, filters?: { daterange: Date[]; search: string }) => {
  return useQuery(
    ['adminSubmissionList', page, filters],
    async () => {
      const params: any = { page };
      if (filters?.daterange?.[0] && filters?.daterange?.[1]) {
        params.startDate = filters?.daterange?.[0];
        params.endDate = filters?.daterange?.[1];
      }
      if (filters?.search) params.search = filters?.search;
      return await AdminSubmissionsModel.GetSubmissions(params);
    },
    {
      keepPreviousData: true,
    },
  );
};

const useGetSubmission = (id: string) => {
  return useQuery(['adminSubmissionView'], () => {
    return AdminSubmissionsModel.GetSubmission(id);
  });
};

const useExportSubmissions = () => {
  return useMutation(['adminExportSubmissions'], () => {
    return AdminSubmissionsModel.ExportSubmissions();
  });
};

const useImportSubmissions = () => {
  return useMutation((file: File | undefined) => {
    if (!file) {
      throw new Error('File is not selected');
    }
    return AdminSubmissionsModel.ImportSubmissions(file);
  });
};

const useImportScopeSubmissions = () => {
  return useMutation((file: File | undefined) => {
    if (!file) {
      throw new Error('File is not selected');
    }
    return AdminSubmissionsModel.ImportScopeSubmissions(file);
  });
};

const useImportRgmZip = () => {
  return useMutation((file: File | undefined) => {
    if (!file) {
      throw new Error('File is not selected');
    }
    return AdminSubmissionsModel.ImportZipFile(file);
  });
};

const useUpdateSubmission = (id: string) => {
  return useMutation(async (formDetails: unknown) => {
    return await AdminSubmissionsModel.UpdateSubmission(id, formDetails);
  }, {});
};

export {
  useGetSubmissions,
  useExportSubmissions,
  useGetSubmission,
  useUpdateSubmission,
  useImportSubmissions,
  useImportScopeSubmissions,
  useImportRgmZip,
};
