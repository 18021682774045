import { Button, Group, Modal, TextInput } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import { useDisclosure } from '@mantine/hooks';
import { CreateUserSchema } from '../schema';
import { ICreateUserPayload } from '../types';
import { useCreateCustomer } from '../hooks';
import { showNotification } from '@mantine/notifications';
import { FunctionComponent, useEffect } from 'react';
import { IErrorObject } from '../../../services/types';
import { USER_TYPES } from '../constants';

interface CreateUserProps {
  refetch: () => void;
  userType: string;
}

export const CreateUser: FunctionComponent<CreateUserProps> = ({ refetch, userType }) => {
  const [opened, { open, close }] = useDisclosure(false);
  const { mutate, isSuccess, isError, error } = useCreateCustomer(userType);

  const form = useForm({
    validate: yupResolver(CreateUserSchema),
    initialValues: {
      name: '',
      phone: '',
      email: '',
      password: '',
      confirmPassword: '',
    },
  });

  const handleOnSubmit = (form: ICreateUserPayload) => {
    const user: ICreateUserPayload = {
      name: form.name,
      phone: form.phone,
      email: form.email,
      password: form.password,
    };
    mutate(user);
  };

  useEffect(() => {
    if (isSuccess) {
      showNotification({
        message: 'User has benn created.',
        title: 'Success',
        autoClose: 3000,
        color: 'green',
      });
      close();
      refetch();
    }
    // adding close calls showNotification 2x
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      const errorResponse: IErrorObject = error as IErrorObject;
      showNotification({
        message: errorResponse.error.message,
        title: 'Failed',
        autoClose: 3000,
        color: 'red',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  const title = userType === USER_TYPES['CUSTOMER_USER'] ? 'Create customer user' : 'Create super user';

  return (
    <>
      <Button sx={{ marginRight: '3rem' }} onClick={open}>
        Create User
      </Button>
      <Modal closeOnClickOutside={false} opened={opened} title={title} onClose={close}>
        <form onSubmit={form.onSubmit(values => handleOnSubmit(values))}>
          <TextInput {...form.getInputProps('name')} label="Name" />
          <TextInput {...form.getInputProps('phone')} label="Phone" />
          <TextInput {...form.getInputProps('email')} label="Email" />
          <TextInput {...form.getInputProps('password')} label="Password" type="password" />
          <TextInput {...form.getInputProps('confirmPassword')} label="Comfirm Password" type="password" />
          <Group position="center" sx={{ marginTop: '1rem' }}>
            <Button variant="outline" onClick={close}>
              Cancel
            </Button>
            <Button variant="filled" type="submit">
              Save
            </Button>
          </Group>
        </form>
      </Modal>
    </>
  );
};
