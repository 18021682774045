import CoreConfig from "../../../../config/core";
import CoreService from "../../../../services/core";
import { ICoreServiceProps } from "../../../../services/types";
import { IProject } from "../../../project/types";

class RootModel extends CoreService {
  constructor(path: string, config: ICoreServiceProps) {
    super(path, config);
    this.path = path;
  }

  async GetProjectDetails(): Promise<IProject> {
    
    try {
      const result = await this.Get();
      return result.data;
    } catch (e) {
      throw e;
    }
    
  }
}
export default new RootModel("/", CoreConfig);
