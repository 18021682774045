import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Group, Title } from '@mantine/core';

import { showNotification, updateNotification } from '@mantine/notifications';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { NAV } from '../../../config/navigation';
import { useGetProjectDetails } from '../../login/hooks';
import { IProjectTableWithChild, IProjectTableWithColumns } from '../../project/types';
import { useFormHook, useProcessSubmission, useSortedData } from '../hooks';
import FormProjectTable from './project-table';
import FormProjectTableColumn from './project-table-columsn';
import TableRepeater from './table-repeater';
interface MainProps {
  data: IProjectTableWithChild;
}

export default function MainForm({ data }: MainProps) {
  const initialValues = useSortedData(data);
  const navigate = useNavigate();
  const { data: projectDetails } = useGetProjectDetails();

  const form = useFormHook(data, initialValues);
  const formSubmission = useProcessSubmission();

  const SubmitFomDetails = (values: any) => {
    showNotification({
      id: 'create-data',
      loading: true,
      title: 'Creating Submission',
      message: 'creating.....',
      autoClose: false,
      disallowClose: true,
    });

    formSubmission.mutate(values);
  };

  useEffect(() => {
    if (formSubmission.isSuccess) {
      updateNotification({
        id: 'create-data',
        color: 'teal',
        title: 'Create Submission',
        message:
          'Thanks for your submission. An email has been sent to you with a copy of your submission for your records....',
        icon: <FontAwesomeIcon icon={faCheck} />,
        autoClose: 10000,
        onClose: () => navigate(NAV.USER_SUBMISSIONS_LIST),
      });
    }
  }, [formSubmission.isSuccess, navigate]);

  const foreignChildItems = data?.child?.reduce<{ [key: string]: IProjectTableWithColumns[] }>((acc, item) => {
    if (item.tableConfig?.displayFor) {
      const displayFor = item.tableConfig.displayFor;
      return { ...acc, [displayFor]: [...(acc[displayFor] || []), item] };
    }

    return acc;
  }, {});
  let template:
    | {
        __html: string;
      }
    | undefined;

  if (projectDetails?.projectConfig?.contents?.submissionHeadingContent) {
    template = { __html: projectDetails.projectConfig.contents.submissionHeadingContent };
  }

  return (
    <>
      <Group align="center" direction="column">
        <Title pl={'md'} pt={'md'}>
          {projectDetails?.projectConfig.contents?.submissionHeading}
        </Title>
        <div dangerouslySetInnerHTML={template}></div>
      </Group>
      <form onSubmit={form.onSubmit(SubmitFomDetails)}>
        {data.child
          ?.filter(table => !table.tableConfig?.displayFor)
          ?.map(table => {
            const { tableConfig, projectTableId } = table;

            const foreignChildren = (foreignChildItems[projectTableId] || []).map(item => (
              <div key={item.projectTableId}>
                {!item.tableConfig?.isTableDisplay ? (
                  <FormProjectTableColumn
                    tableConfig={tableConfig}
                    form={form}
                    tableName={item.tableName}
                    columns={item.columns}
                  />
                ) : (
                  <TableRepeater
                    tableConfig={tableConfig}
                    key={`${item.projectTableId}`}
                    form={form}
                    table={item}
                    tableName={item.tableName}
                  />
                )}
              </div>
            ));

            return (
              <FormProjectTable
                tableName={table.tableName}
                isSubTable={tableConfig?.isSubTable ?? false}
                description={table.description}
                key={table.projectTableId}
              >
                {foreignChildren}

                {!tableConfig?.isTableDisplay ? (
                  <FormProjectTableColumn
                    tableConfig={tableConfig}
                    form={form}
                    tableName={table.tableName}
                    columns={table.columns}
                  />
                ) : (
                  <TableRepeater
                    tableConfig={tableConfig}
                    key={`${table.projectTableId}`}
                    form={form}
                    table={table}
                    tableName={table.tableName}
                  />
                )}
              </FormProjectTable>
            );
          })}

        <Group position="center" direction="column" mb={'xl'}>
          <Button type="submit" color={'dark'} size="lg" m={'lg'} loading={formSubmission.isLoading}>
            Submit
          </Button>
        </Group>
      </form>
    </>
  );
}
