import { PathRouteProps } from 'react-router-dom';
import { NAV } from './config/navigation';
/* component entry points */
import Notification from './component/common/notification';
import { Form } from './pages/form-field';
import CustomerLogin from './pages/login/auth';
import Register from './pages/register';
import RegisterConfirmation from './pages/register/component/confirm';
import UserSubmissionList from './pages/submission/me/';
import UserSubmissionView from './pages/submission/me/view';
import { ProjectPage } from './pages/project/pages';
import ResetPassword from './pages/reset-password';
import SetPassword from './pages/set-password';

/** ADMIN page components **/
import SubmissionsList from './admin/submissions/list';
import SubmissionsView from './admin/submissions/view';
import ProjectSettings from './admin/project-settings/';
import UserList from './admin/user/';

const publicRoutes: PathRouteProps[] = [
  { path: NAV.LOGIN, element: <CustomerLogin /> },
  { path: NAV.HOME, element: <ProjectPage forcePath={'index'} /> },
  { path: NAV.REGISTER, element: <Register /> },
  { path: NAV.REGISTER_NOTIF, element: <Notification registration /> },
  { path: NAV.REGISTER_SUCCESS, element: <Notification verified /> },
  { path: NAV.REGISTER_CONFIRM, element: <RegisterConfirmation /> },
  { path: NAV.RESET_PASSWORD, element: <ResetPassword /> },
  { path: NAV.RESET_PASSWORD_SENT, element: <Notification resetPassword /> },
  { path: NAV.RESET_PASSWORD_SUCCESS, element: <Notification changed /> },
  { path: NAV.SET_PASSWORD, element: <SetPassword /> },
  { path: NAV.PROJECT_PAGE, element: <ProjectPage /> },
];

const protectedRoutes = [
  { path: NAV.FORM, element: <Form /> },
  { path: NAV.USER_SUBMISSIONS_LIST, element: <UserSubmissionList /> },
  { path: NAV.USER_SUBMISSIONS_VIEW, element: <UserSubmissionView /> },
];

const adminProtectedRoutes = [
  { path: NAV.ADMIN, element: <SubmissionsList /> },
  { path: NAV.ADMIN_SUBMISSION_VIEW, element: <SubmissionsView /> },
  { path: NAV.ADMIN_PROJECT_SETTINGS, element: <ProjectSettings /> },
  { path: NAV.ADMIN_USER_LIST, element: <UserList /> },
];

export { publicRoutes, protectedRoutes, adminProtectedRoutes };
