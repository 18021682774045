import { useGetProjectDetails } from '../login/hooks';
import { FC, PropsWithChildren } from 'react';
import { Helmet } from 'react-helmet';
import { randomId } from '@mantine/hooks';

const hotelQuarantineDomain = 'hotelquarantineclassaction.com.au';

export const ProjectPageComponent: FC<PropsWithChildren> = ({ children }) => {
  const { data } = useGetProjectDetails();

  const css = data
    ? `
    * {
    font-family: ${data.projectConfig.theme.paragraphFont}, Arial !important;
   }

   h1, h2, h3, h4, h5, h6 {
    font-family: ${data.projectConfig.theme.headingFont}, Arial !important;
   }
  `
    : ``;

  const externalStyles = data?.projectConfig.contents.styles || [];

  const favicon = data?.projectConfig.theme.favicon;

  const projectDomain = data?.domainName || '';

  return (
    <>
      <Helmet>
        <style>{css}</style>

        {externalStyles.map(link => (
          <link key={randomId()} href={link} rel="stylesheet" />
        ))}

        {favicon && <link rel="shortcut icon" href={favicon} type="image/icon" />}

        {'<!-- React Helmet does not support fragments <></> -->'}
        {'<!-- so tags are added individually -->'}
        {'<!-- Meta Pixel Code -->'}
        {projectDomain === hotelQuarantineDomain && (
          <meta name="facebook-domain-verification" content="tbctppht33sijwtzmvzf76xhq7o23z" />
        )}
        {projectDomain === hotelQuarantineDomain && (
          <script>
            {`!function(f,b,e,v,n,t,s)
                {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                n.queue=[];t=b.createElement(e);t.async=!0;
                t.src=v;s=b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t,s)}(window, document,'script',
                'https://connect.facebook.net/en_US/fbevents.js');
                fbq('init', '1456866268232427');
                fbq('track', 'PageView');`}
          </script>
        )}
        {'<!-- End Meta Pixel Code -->'}

        {'<!-- Google Analytics Code -->'}
        {projectDomain === hotelQuarantineDomain && (
          <script async src="https://www.googletagmanager.com/gtag/js?id=G-ERNN60TCTZ"></script>
        )}
        {projectDomain === hotelQuarantineDomain && (
          <script>
            {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());   
          
            gtag('config', 'G-ERNN60TCTZ'); 
            `}
          </script>
        )}
        {'<!-- <!-- Google tag (gtag.js) -->'}
      </Helmet>

      {children}
    </>
  );
};
