import CoreConfig from '../../config/core';
import CoreService from '../../services/core';
import CatchError from '../../services/error';
import { ICoreServiceProps, ICoreServiceResponse } from '../../services/types';

class AdminSubmissionModel extends CoreService {
  constructor(path: string, config: ICoreServiceProps) {
    super(path, config);
    this.path = path;
  }

  async GetSubmissions(params: any) {
    try {
      const result: ICoreServiceResponse = await this.axios.get(
        `${this.path}?${new URLSearchParams(params).toString()}`,
        params,
      );
      return result.data;
    } catch (e: any) {
      throw CatchError.Negotiate(e);
    }
  }

  async GetSubmissionsTotal() {
    try {
      const result = await this.axios.get(this.path + '/stats');
      return result.data.data;
    } catch (e: any) {
      throw CatchError.Negotiate(e);
    }
  }

  async GetSubmission(id: string) {
    try {
      const result: ICoreServiceResponse = await this.axios.get(`${this.path}/${id}`, {});
      return result.data.data;
    } catch (e: any) {
      throw CatchError.Negotiate(e);
    }
  }

  async ExportSubmissions() {
    try {
      const result: ICoreServiceResponse = await this.axios.post(`/submissions/export`, {});
      return result.data.data;
    } catch (e: any) {
      throw CatchError.Negotiate(e);
    }
  }

  GetExportUrl() {
    return `${this.GetBaseUrl()}submissions/export`;
  }

  GetExportScopeUrl(scope: string) {
    return `${this.GetBaseUrl()}submissions/export-scope?exportScope=${scope}`;
  }

  async ImportSubmissions(file: File) {
    try {
      const formData = new FormData();
      formData.append('file', file);

      const result: ICoreServiceResponse = await this.axios.post(`/submissions/import`, formData);
      return result.data.data;
    } catch (e: any) {
      throw CatchError.Negotiate(e);
    }
  }

  async ImportZipFile(file: File) {
    try {
      const formData = new FormData();
      formData.append('file', file);

      const result: ICoreServiceResponse = await this.axios.post(`/file-manager/upload-zip`, formData);
      return result.data.data;
    } catch (e: any) {
      throw CatchError.Negotiate(e);
    }
  }

  async ImportScopeSubmissions(file: File) {
    try {
      const formData = new FormData();
      formData.append('file', file);

      const result: ICoreServiceResponse = await this.axios.post(`/submissions/import-scope`, formData);
      return result.data.data;
    } catch (e: any) {
      throw CatchError.Negotiate(e);
    }
  }

  async UpdateSubmission(id: string, bodyParams: any) {
    try {
      const result: ICoreServiceResponse = await this.axios.patch(`/admin/submissions/${id}`, bodyParams);
      return result.data.data;
    } catch (e: any) {
      throw CatchError.Negotiate(e);
    }
  }

  async GetRgmFile(id: string) {
    try {
      const result: ICoreServiceResponse = await this.axios.get(`/file-manager/get-file/${id}`);
      return result.data.data;
    } catch (e: any) {
      throw CatchError.Negotiate(e);
    }
  }

  async GetFinalNoticeRgmFile(id: string) {
    try {
      const result: ICoreServiceResponse = await this.axios.get(`/file-manager/get-file/${id}/final-notice`);
      return result.data.data;
    } catch (e: any) {
      throw CatchError.Negotiate(e);
    }
  }
}
export default new AdminSubmissionModel('/admin/submissions', CoreConfig);
