import { useMutation } from 'react-query';
import { IErrorObject } from '../../services/types';
import SetPasswordModel from './model/set-password.model';
import { useForm, yupResolver } from '@mantine/form';
import { ISetPasswordFormProps } from './types';
import { setPasswordSchema } from './schema';

export function useSetPasswordForm() {
  return useForm({
    initialValues: {
      resetCode: '',
      password: '',
      confirmPassword: ''
    },
    validate: yupResolver(setPasswordSchema),
  });
}

export const useProcessSetNewPassword = () => {
  return useMutation<void, IErrorObject, ISetPasswordFormProps, void>(
    async ({ resetCode, password }: ISetPasswordFormProps) => {
      return await SetPasswordModel.SetNewPassword({ resetCode, password });
    },
    {}
  );
};
