interface IErrorResponse {
   status: number;
   name: string;
   message: string;
}
interface IErrorObject {
   error: IErrorResponse;
   meta?: any;
}
class CatchError {
   static Negotiate(e: any): IErrorObject {
      let error: IErrorResponse = {
         status: 500,
         name: e.name,
         message: e.message,
      };
      let meta = {};
      if (e.response) {
         error = e.response.data.error;
         meta = e.response.data.meta;
      }
      return {
         error,
         meta,
      };
   }
}

export default CatchError;
