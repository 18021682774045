import { Button, Container } from '@mantine/core';
import { useGetFinalRgmDoc } from '../../../../hooks/rgm.hook';
import { useParams } from 'react-router-dom';

export const FinalDistributionNotice = () => {
  const content = `
      <style>
         #hr-fd {
            box-sizing: content-box;
            height: 0;
            overflow: visible;
         }
         #final-notice h3 {
            text-transform: uppercase;
            font-family: "Nunito", sans-serif !important;
         }

         .bold-text {
            font-weight: 700;
         }

         #edn-content ol li {
             margin: 1em;
         }

         .alpha-ordered-list {
            list-style-type:none;
         }

         .alpha-ordered-list li {
           
            counter-increment:section;
         }

         .alpha-ordered-list li:before {
            content:"(" counter(section, upper-alpha) ") ";
         }

         .edn-schedule-a {
            text-align: center;
         }

         .content-link-container{
            margin: 20px 0;
         }

         
         
      </style>

      <hr id="hr-en"/>
      <article id="edn-content" class="standard">
         <h3>FINAL DISTRIBUTION NOTICE - SETTLEMENT DISTRIBUTION SCHEME</h3>

         <div class="content-wrapper">
            <div class="content">
               <table id="table">
                  <tr>
                     <td>
                        Proceedings name:
                     </td>
                     <td>
                        Peter Hermann Eckardt v Sims Limited ACN 114 838 630 Sims Class Action - Administration of Settlement  
                     </td>
                  </tr>
                  <tr>
                     <td>
                        Proceedings number:
                     </td>
                     <td>
                        NSD220/2019
                     </td>
                  </tr>
                  <tr>
                     <td>
                        Court:
                     </td>
                     <td>
                        Federal Court of Australia
                     </td>
                  </tr>
                  <tr>
                     <td>
                        Lawyers:
                     </td>
                     <td>
                        William Roberts Lawyers (<span class="bold-text">William Roberts</span>)
                     </td>
                  </tr>
                  <tr>
                     <td>
                        Litigation Funder(s):
                     </td>
                     <td>
                        Investor Claim Partner Pty Ltd, ICP Capital Pty Limited (<span class="bold-text">ICP</span>) 
                     </td>
                  </tr>
               </table>
            </div>
         </div>
      </article>
     
   `;

  const params = useParams();
  const { fileName, previewUrl, isError, isSuccess } = useGetFinalRgmDoc(params.id || '');

  return (
    <Container className="final-notice" id="final-notice" sx={{ maxWidth: 'unset', marginTop: '5rem' }}>
      <div style={{ width: '100%' }} dangerouslySetInnerHTML={{ __html: content }}></div>

      {isError && (
        <div>
          <p>Cannot generate RGM Doc Link.</p>
        </div>
      )}

      {isSuccess && (
        <div
          style={{
            marginTop: '2rem',
          }}
        >
          <p>
            <span style={{ fontWeight: 700 }}>File Name: </span>
            {fileName}
          </p>
          <Button component="a" href={previewUrl} rel="noreferrer" target="_blank" style={{ width: 'auto' }}>
            Click here to view the RGM's Final Distribution Notice.
          </Button>
        </div>
      )}
    </Container>
  );
};
