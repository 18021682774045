import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Avatar,
  Button,
  Divider,
  Image,
  Menu,
  Text,
  Title,
  Header as MantineHeader,
  createStyles,
  Burger,
  Drawer,
  ScrollArea,
  Paper,
  Group,
  Anchor,
} from '@mantine/core';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { NAV } from '../../config/navigation';
import localStorage from '../../helpers/local-storage';
import { useGetProjectDetails, useProcessLogout } from '../../pages/login/hooks';
import { useDisclosure } from '@mantine/hooks';
import { CheckIfSims } from '../../utils/helper';

export function Header() {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const { data } = useGetProjectDetails();
  const { mutate } = useProcessLogout();

  const handleLogout = async () => {
    mutate();
    localStorage.ClearAll();
    navigate(NAV.LOGIN);
  };
  const adminMenu = [
    {
      label: 'Submissions',
      route: NAV.ADMIN,
    },
    {
      label: 'Project Settings',
      route: NAV.ADMIN_PROJECT_SETTINGS,
    },
    {
      label: 'User Management',
      route: NAV.ADMIN_USER_LIST,
    },
  ];

  const headerStyle: React.CSSProperties = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '1rem',
    backgroundColor: '#f1f1f1',
  };

  const alignCenter: React.CSSProperties = {
    display: 'flex',
    alignItems: 'center',
  };

  const useStyles = createStyles(theme => ({
    hiddenMobile: {
      [theme.fn.smallerThan('lg')]: {
        display: 'none',
      },
    },

    hiddenDesktop: {
      [theme.fn.largerThan('lg')]: {
        display: 'none',
      },
    },

    navItems: {
      display: 'flex',
      alignItems: 'center',
    },

    projectHeading: {
      minWidth: '15rem',
      overflow: 'hidden',
      color: '#818181',
      fontSize: '1.7rem'
    },
  }));
  const isSims = CheckIfSims();
  const [drawerOpened, { toggle: toggleDrawer, close: closeDrawer }] = useDisclosure(false);

  const { classes, cx } = useStyles();

  return (
    <>
      {data && (
        <>
          <MantineHeader height={100} style={headerStyle}>
            <div style={alignCenter}>
              <Image src={data.projectConfig.contents.logo} withPlaceholder fit="contain" width={200} />
              <Title pl={'lg'} className={cx(classes.projectHeading, classes.hiddenMobile)}>
                {data.projectConfig.contents.heading}
              </Title>
            </div>

            <div style={{ flex: 1 }} />
            <Burger opened={drawerOpened} onClick={toggleDrawer} size="sm" className={classes.hiddenDesktop} />

            <div className={cx(classes.navItems, classes.hiddenMobile)}>
              {
                isSims && <Button
                variant="subtle"
                p={'xs'}
                size={'md'}
                component={Link}
                to={NAV.ADMIN}
              >
                Dashboard
              </Button>
              }
              <Menu
                placement="start"
                size={'lg'}
                onClose={() => setOpen(false)}
                onOpen={() => setOpen(true)}
                pr={'lg'}
                control={
                  <Button
                    variant="subtle"
                    rightIcon={<FontAwesomeIcon icon={open ? faCaretUp : faCaretDown} />}
                    p={'xs'}
                    size={'md'}
                  >
                    Project Admin Menu
                  </Button>
                }
              >
                {adminMenu.map((item: { label: string; route: string }) => {
                  return (
                    <Menu.Item component={Link} to={item.route} key={item.route}>
                      {item.label}
                    </Menu.Item>
                  );
                })}
              </Menu>
              <Divider
                orientation="vertical"
                size={'md'}
                variant="solid"
                sx={{ height: '2.5rem', margin: '1rem' }}
                color="dark"
              />
              <Text color="dimmed" size="xl">
                {localStorage.Get('email')}
              </Text>
              <Divider
                orientation="vertical"
                size={'md'}
                variant="solid"
                sx={{ height: '2.5rem', margin: '1rem' }}
                color="dark"
              />
              <Button
                variant="outline"
                styles={{
                  outline: { borderColor: 'gray', color: 'dimgray' },
                }}
                onClick={handleLogout}
              >
                Logout
              </Button>
              <Avatar radius="xl" ml={'xl'} size="lg" styles={{ root: { border: '1px black solid' } }} />
            </div>
          </MantineHeader>
          <Drawer
            opened={drawerOpened}
            onClose={closeDrawer}
            size="100%"
            padding="md"
            sx={{
              backgroundColor: 'rgb(241, 241, 241)',
            }}
            title={data.projectConfig.contents.heading}
            className={classes.hiddenDesktop}
            zIndex={1000000}
          >
            <ScrollArea sx={{ height: '100vh' }} mx="-md">
              <Divider my="sm" />

              <Paper p={'1rem'}>
                <Text>Project Admin Menu</Text>
                {adminMenu.map((item: { label: string; route: string }) => {
                  return (
                    <div key={item.route}>
                      <Anchor href={item.route}> {item.label}</Anchor>
                    </div>
                  );
                })}

                <Text color="dimmed" size="sm">
                  {localStorage.Get('email')}
                </Text>
              </Paper>

              <Divider my="sm" />

              <Group position="center" grow pb="xl" px="md">
                <Button
                  variant="outline"
                  styles={{
                    outline: { borderColor: 'gray', color: 'dimgray' },
                  }}
                  onClick={handleLogout}
                >
                  Logout
                </Button>
              </Group>
            </ScrollArea>
          </Drawer>
        </>
      )}
    </>
  );
}
