import { Checkbox, Group } from '@mantine/core';
import { useEffect, useState } from 'react';
import { FormFieldProps } from './types';

export default function InputCheckbox({ hideLabel = false, ...props }: FormFieldProps) {
  const [inputProps, setinputProps] = useState(`${props.tableName}.${props.columnName}`);

  useEffect(() => {
    if (props.index !== undefined) setinputProps(`${props.tableName}.${props.index}.${props.columnName}`);
  }, [props.columnName, props.index, props.tableName]);

  return (
    <Group position="center" mt={'md'}>
      <Checkbox
        placeholder={props.description}
        label={!hideLabel && props.description}
        {...props.form.getInputProps(inputProps)}
        checked={props.form.values[props.tableName][props.columnName]}
        size={16}
      />
    </Group>
  );
}
