import { ITableHeaderMap } from '../../types';

const SubmissionListThead = ({ headers }: { headers: ITableHeaderMap }) => {
  const tableHeaders: JSX.Element[] = [
    <th className="submission-table-header-name" key={'action-header'}>
      Action
    </th>,
  ];

  const headCols = Object.keys(headers).map((tableName: string, childInc: number) => {
    const { columns } = headers[tableName];

    tableHeaders.push(
      <th className="submission-table-header-name" colSpan={Object.keys(columns).length} key={childInc}>
        {headers[tableName].label}
      </th>,
    );
    return Object.keys(columns).map((columnName: string) => {
      return (
        <th className="submission-table-header-name" key={columnName}>
          {columns[columnName]}
        </th>
      );
    });
  });
  return (
    <thead>
      <tr>{tableHeaders}</tr>
      <tr>
        <th className="submission-table-header-name" key={'action-view'}>
          View
        </th>
        {headCols}
      </tr>
    </thead>
  );
};

export default SubmissionListThead;
