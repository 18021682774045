// import { useAccordionState } from "@mantine/core";
import { Suspense, useEffect } from 'react';
import { PathRouteProps, Route, Routes, useLocation } from 'react-router-dom';

import Loader from './component/common/loader';

import { QueryClient, QueryClientProvider } from 'react-query';
import PageNotFound from './component/common/page-not-found';
import LocalStorage from './helpers/local-storage';

import { PrivateRoute } from './component/PrivateRoute';
import { NAV } from './config/navigation';
import { adminProtectedRoutes, protectedRoutes, publicRoutes } from './routes';
import AdminProtectedRoute from './component/AdminRoute';
import { ProjectPageComponent } from './pages/project/project-page.component';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  // const [state, handlers] = useAccordionState({ total: 3, initialItem: 0 });
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname === NAV.HOME) LocalStorage.SetUserType('customer');
    if (pathname === NAV.ADMIN) LocalStorage.SetUserType('admin');
  }, [pathname]);

  return (
    <QueryClientProvider client={queryClient}>
      <Suspense fallback={<Loader />}>
        <ProjectPageComponent>
          <Routes>
            {publicRoutes.map((route: PathRouteProps, i) => (
              <Route {...route} key={i} />
            ))}

            <Route element={<PrivateRoute />}>
              {protectedRoutes.map((route: PathRouteProps, i) => (
                <Route {...route} key={i} />
              ))}
            </Route>

            <Route element={<AdminProtectedRoute />}>
              {adminProtectedRoutes.map((route: PathRouteProps, i) => (
                <Route {...route} key={i} />
              ))}
            </Route>

            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </ProjectPageComponent>
      </Suspense>
    </QueryClientProvider>
  );
}

export default App;
