import { useGetProjectDetails } from '../../pages/login/hooks';
import { createStyles } from '@mantine/core';

const Footer = () => {
  const { data } = useGetProjectDetails();
  const useStyles = createStyles(theme => ({
    footer: {
      display: 'none',

      [theme.fn.largerThan('sm')]: {
        display: 'block',
      },
    },
  }));

  const { classes } = useStyles();

  let template:
    | {
        __html: string;
      }
    | undefined;

  if (data) {
    template = { __html: data?.projectConfig.contents.footer };
  }

  return <div className={classes.footer} dangerouslySetInnerHTML={template} />;
};

export { Footer };
