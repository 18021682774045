import { useGetPage } from './hooks';
import OverlayLoader from '../../../component/common/loading-overlay';
import Alert from '../../../component/common/alert';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { NAV } from '../../../config/navigation';

interface CustomPageProps {
  path: string;
}

export const CustomPage = ({ path }: CustomPageProps) => {
  const { isLoading, data, isError, error } = useGetPage(path);
  const navigate = useNavigate();

  useEffect(() => {
    const scripts = data?.scripts?.map(src => {
      const script = document.createElement('script');

      script.src = src;
      script.async = false;

      document.body.appendChild(script);

      return script;
    });

    return () => scripts?.forEach(script => document.body.removeChild(script));
  }, [data]);

  if (isError && error.error.status === 404) {
    navigate(NAV.LOGIN);

    return <></>;
  }

  let template:
    | {
        __html: string;
      }
    | undefined;

  if (data) {
    template = { __html: data.content };
  }

  return (
    <div>
      <Helmet async={true}>
        <title>{data?.title}</title>
      </Helmet>

      {isLoading && <OverlayLoader visible={isLoading} />}
      {isError && <Alert message={error.message} />}

      {data && (
        <div>
          <div dangerouslySetInnerHTML={template}></div>
        </div>
      )}
    </div>
  );
};
