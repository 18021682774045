import { Button, Group, Paper, Text, Title } from '@mantine/core';
import { Link } from 'react-router-dom';
import { NAV } from '../../config/navigation';
import { container } from './styles';

interface Props {
  resetPassword?: boolean;
  registration?: boolean;
  verified?: boolean;
  changed?: boolean;
}

const Notification = ({ resetPassword, registration, verified, changed }: Props) => {
  return (
    <div style={container}>
      <Paper shadow={'xl'} withBorder radius={'sm'} p={'4rem'}>
        <Group direction="column" align={'center'} spacing={'lg'}>
          {/* If email is reset password or registration verification email */}
          {(resetPassword || registration) && <Title>Email Verification</Title>}

          {registration && <Text>Please confirm to complete your registration</Text>}
          {resetPassword && (
            <>
              <Text>We sent an email to complete your password reset</Text>
              <Text>Tip: Check your spam folder in case the email was incorrectly identified</Text>
            </>
          )}

          {registration && (
            <>
              <Text>Tip: Check your spam folder in case the email was incorrectly identified</Text>
              <Text>Or</Text>
              <Button>{registration ? 'Resend Email' : 'Change Email'}</Button>
            </>
          )}

          {/* If email is verified */}
          {verified && (
            <>
              <Title>Your email is now verified.</Title>
              <Text<typeof Link>
                underline
                size="sm"
                component={Link}
                to={NAV.HOME}
                sx={{
                  cursor: 'pointer',
                  '&:hover': { color: '#228be6' },
                }}
              >
                Click here to sign in.
              </Text>
            </>
          )}

          {/* password is successfully changed */}
          {changed && (
            <>
              <Title>You have successfully changed your password.</Title>
              <Text<typeof Link>
                underline
                size="sm"
                component={Link}
                to={NAV.HOME}
                sx={{
                  cursor: 'pointer',
                  '&:hover': { color: '#228be6' },
                }}
              >
                Click here to sign in.
              </Text>
            </>
          )}
        </Group>
      </Paper>
    </div>
  );
};

export default Notification;
