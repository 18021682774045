import { useForm, yupResolver } from '@mantine/form';
import { useMutation, useQuery } from 'react-query';
import * as Yup from 'yup';
import { useYupSchema } from '../form-field/schema';
import { ColumnType } from '../form-field/types';
import { IProjectTableWithChild } from '../project/types';
import SubmissionModel from './submission.model';
import { IInsolvencyUserSubmission } from './types';
const useGetUserSubmissions = () => {
  return useQuery<IInsolvencyUserSubmission[], any>(['projectSubmissionForms'], () => {
    return SubmissionModel.GetUserSubmissions();
  });
};

const useGetUserSubmission = (id: string) => {
  return useQuery<IInsolvencyUserSubmission, any>(['projectUserSubmissionForm'], () => {
    return SubmissionModel.GetUserSubmission(id);
  });
};

const useEditData = (dataItem: IProjectTableWithChild, editData: any) =>
  dataItem.child.reduce((acc, childItem) => {
    if (childItem.tableConfig?.isTableDisplay) {
      const editTable = editData[childItem.tableName].map((row: any) =>
        childItem.columns.reduce((childAcc, childColumn) => {
          const value = row[childColumn.columnName];
          if (childColumn.columnType === ColumnType.DatePicker) {
            return {
              ...childAcc,
              [childColumn.columnName]: new Date(value),
              key: row.id,
            };
          }
          if (childColumn.columnType === ColumnType.Number) {
            return {
              ...childAcc,
              [childColumn.columnName]: value ? parseInt(value) : 0,
              key: row.id,
            };
          }
          return {
            ...childAcc,
            [childColumn.columnName]: Array.isArray(value) ? `{${value}}` : value?.toString(),
            key: row.id,
          };
        }, {}),
      );

      return {
        ...acc,
        [childItem.tableName]: editTable,
      };
    }

    return {
      ...acc,
      [childItem.tableName]: childItem.columns.reduce((childAcc, childColumn) => {
        let value = '';
        // check if editData has the property we need
        // override value with data from submission
        if (editData[childItem.tableName] && editData[childItem.tableName][childColumn.columnName]) {
          value = editData[childItem?.tableName][childColumn?.columnName];
        }

        return {
          ...childAcc,
          [childColumn.columnName]: Array.isArray(value) ? `{${value[0]}}` : value,
        };
      }, {}),
    };
  }, {});

const useFormEditHook = (data: IProjectTableWithChild, editData: IInsolvencyUserSubmission) => {
  const initialValues = useEditData(data, editData);
  const schemaNew = Yup.object().shape(useYupSchema(data));

  return useForm({
    initialValues,
    validate: yupResolver(schemaNew),
  });
};

// const useProcessLogin = () => {
//    return useMutation<any, IErrorObject>(
//       async ({ user, password }: ILoginFormProps) => {
//          return await AuthModel.Authenticate(user, password);
//       },
//       {},
//    );
// };

const useUpdateMySubmission = (id: string) => {
  return useMutation(async (formDetails: unknown) => {
    return await SubmissionModel.UpdateMySubmission(id, formDetails);
  }, {});
};

//use ca
export { useGetUserSubmissions, useGetUserSubmission, useUpdateMySubmission, useFormEditHook };
