import { ColumnType } from '../../../pages/form-field/types';
import { IProjectTableColumn, IProjectTableWithColumns } from '../../../pages/project/types';
import { ITableHeaderMap } from '../types';

const GetTableHeaders = (child: IProjectTableWithColumns[]) => {
  const tableHeader: ITableHeaderMap = {
    submissions: {
      key: 'submissions',
      label: 'Submission',
      columns: {
        created_on: 'Date Submitted',
        updated_on: 'Last Updated',
        email: 'Submitted By',
      },
    },
  };

  child.forEach((itemTable: IProjectTableWithColumns) => {
    const { columns } = itemTable;
    const removeReadOnlyColumns = columns.filter(
      col => col.columnType !== ColumnType.Paragraph && col.columnType !== ColumnType.SubHeading,
    );
    const cols: any = {};
    removeReadOnlyColumns.forEach((itemColumn: IProjectTableColumn) => {
      cols[itemColumn.columnName] = itemColumn.description;
    });
    tableHeader[itemTable.tableName] = {
      key: itemTable.tableName,
      label: itemTable.description,
      columns: cols,
    };
  });

  return tableHeader;
};

export { GetTableHeaders };
