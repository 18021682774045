import { Container, Group } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import Alert from '../../component/common/alert';
import Loader from '../../component/common/loader';
import LoginBranding from '../login/auth/components/branding';
import { useGetProjectDetails } from '../login/hooks';
import { useGetPageSetPassword, useGetPageSetPasswordFooter } from '../project/pages/hooks';
import ResetPasswordForm from './component/form';
import { useProcessPasswordResetLink, useResetPasswordForm } from './hooks';
import { resetPasswordContainerStyle } from './styles';
import { ContainerMainStyles, DivContent, FlexDirection, GroupContent } from '../auth-styles';

export default function ResetPassword() {
  const { error, data, ...projectDetails } = useGetProjectDetails();
  const largeScreen = useMediaQuery('(min-width: 800px)');
  const hookForm = useResetPasswordForm();
  const hookResetPassword = useProcessPasswordResetLink();
  const { data: passwordPageData, isLoading: setPasswordIsLoading } = useGetPageSetPassword();
  const footerData = useGetPageSetPasswordFooter();

  if (projectDetails.isLoading || setPasswordIsLoading) {
    return <Loader />;
  }

  if (error) {
    return <Alert message="An error occured while loading the page..." color="red" />;
  }

  return (
    <Container fluid={data && !passwordPageData?.content} sx={data && !passwordPageData?.content ? resetPasswordContainerStyle : {}} style={ data && !passwordPageData?.content ? {} : ContainerMainStyles}>
      <Group
        direction={FlexDirection(passwordPageData)}
        align={'center'}
        style={GroupContent}
        position="center"
        spacing={largeScreen ? 50 : 0}
      >
        {
          passwordPageData?.content && <div style={DivContent} dangerouslySetInnerHTML={{ __html: passwordPageData.content }} />
        }
        {
          (data && !passwordPageData?.content) && <LoginBranding project={data}/>
        }
        <ResetPasswordForm hookForm={hookForm} onResetPassword={hookResetPassword} />  
      </Group>
      {footerData?.data?.content && <div dangerouslySetInnerHTML={{ __html: footerData?.data?.content ?? '' }} />}
    </Container>
  );
}
