import localStorage from '../../../../helpers/local-storage';
import { EstimatedDistributionNotice } from './estimated-distribution.content';
import { FinalDistributionNotice } from './final-distribution.content';

export const renderSimsContent = (tableName: string) => {
  const isAdmin = localStorage.IsAdmin();

  if (tableName === 'ed_rgm_upload') {
    return <EstimatedDistributionNotice />;
  }

  if (tableName === 'fd_review_verify') {
    return <FinalDistributionNotice />;
  }
  return null;
};
