import { NumberInput } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { useEffect, useState } from 'react';
import { FormFieldProps } from './types';

export default function InputNumber({ hideLabel = false, ...props }: FormFieldProps) {
  const [inputProps, setinputProps] = useState(`${props.tableName}.${props.columnName}`);

  useEffect(() => {
    if (props.index !== undefined) setinputProps(`${props.tableName}.${props.index}.${props.columnName}`);
  }, [props.columnName, props.index, props.tableName]);

  const smallScreen = useMediaQuery('(max-width: 800px)');

  const inputValueProps = { ...props.form.getInputProps(inputProps) };
  const isDirty = props.form.isDirty(inputProps);
  const hasInitialValue = !!inputValueProps?.value;

  const disableForm = props.nonEditableFilledForm && !isDirty && hasInitialValue;

  return (
    <NumberInput
      placeholder={props.description}
      label={!hideLabel && props.description}
      {...props.form.getInputProps(inputProps)}
      disabled={disableForm}
      styles={{
        root: {
          display: 'flex',
          alignItems: 'center',
          margin: '0.5rem',
          justifyContent: 'space-between',
          flexFlow: smallScreen ? 'column' : 'row',
        },
        label: {
          paddingRight: '0.5rem',
        },
        error: {
          minWidth: '1rem',
          marginLeft: '10px',
        },
        input: {
          width: '15rem',
        },
      }}
    />
  );
}
