class LocalStorage {
  public static readonly CUSTOMER_KEY = 'tac-customer-id';
  public static readonly PROJECT_ID_KEY = 'tac-project-id';
  public static readonly IS_AUTH_KEY = 'tac-is-authenticated';
  public static readonly USER_TYPE_KEY = 'tac-user-type';
  public static readonly ROLE_ID_KEY = 'tac-role-id';

  Set(key: string, value: any): void {
    localStorage.setItem(key, value);
  }

  Get(key: string): string {
    return localStorage.getItem(key) || '';
  }

  Del(key: string): void {
    localStorage.removeItem(key);
  }

  //------------- helper methods
  GetCustomerID() {
    return this.Get(LocalStorage.CUSTOMER_KEY);
  }

  SaveCustomerID(customerId: string) {
    this.Set(LocalStorage.CUSTOMER_KEY, customerId);
  }

  GetProjectId() {
    return this.Get(LocalStorage.PROJECT_ID_KEY);
  }

  SaveProjectId(projectId: string) {
    this.Set(LocalStorage.PROJECT_ID_KEY, projectId);
  }

  GetIsAuthenticated() {
    return this.Get(LocalStorage.IS_AUTH_KEY);
  }

  SetIsAuthenticated(auth: boolean) {
    this.Set(LocalStorage.IS_AUTH_KEY, auth);
  }

  GetUserType() {
    return this.Get(LocalStorage.USER_TYPE_KEY);
  }

  SetUserType(userType: string) {
    this.Set(LocalStorage.USER_TYPE_KEY, userType);
  }

  GetRoleId() {
    return this.Get(LocalStorage.ROLE_ID_KEY);
  }

  SetRoleId(roleId: string) {
    this.Set(LocalStorage.ROLE_ID_KEY, roleId);
  }

  ClearAll() {
    localStorage.clear();
  }

  IsAdmin() {
    return this.Get(LocalStorage.USER_TYPE_KEY).toLocaleUpperCase() === 'ADMIN';
  }
}

export default new LocalStorage();
