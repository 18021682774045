import { useMutation, useQuery } from 'react-query';
import AdminCustomerUserModel from './AdminCustomerUser.model';
import { ICreateUserPayload, IUserPayload } from './types';
import AdminSuperUserModel from './AdminSuperUser.model';
import { USER_TYPES } from './constants';

const useGetCustomerUsers = (page: number) => {
  const params: any = { page };
  return useQuery(['customer_users', page], () => {
    return AdminCustomerUserModel.GetCustomerUsers(params);
  });
};

const useFindCustomerUserById = (id: string) => {
  return useQuery(['customer_users', id], () => {
    return AdminCustomerUserModel.FindCustomerById(id);
  });
};

const useGetUserById = (id: string, type: string) => {
  return useQuery([type, id], () => {
    if (type === USER_TYPES['CUSTOMER_USER']) {
      return AdminCustomerUserModel.FindCustomerById(id);
    } else {
      return AdminSuperUserModel.FindUserById(id);
    }
  });
};

const useUpdateCustomer = (type: string) => {
  return useMutation(async (payload: IUserPayload) => {
    if (type === USER_TYPES['CUSTOMER_USER']) {
      return AdminCustomerUserModel.UpdateCustomerUser(payload);
    } else {
      return AdminSuperUserModel.UpdateSuperUser(payload);
    }
  }, {});
};

const useDeleteCustomer = (type: string) => {
  return useMutation(async (id: string) => {
    if (type === USER_TYPES['CUSTOMER_USER']) {
      return AdminCustomerUserModel.DeleteCustomerUser(id);
    } else {
      return AdminSuperUserModel.DeleteSuperUser(id);
    }
  }, {});
};

const useCreateCustomer = (type: string) => {
  return useMutation(async (payload: ICreateUserPayload) => {
    if (type === USER_TYPES['CUSTOMER_USER']) {
      return AdminCustomerUserModel.CreateCustomer(payload);
    } else {
      return AdminSuperUserModel.CreateSuperUser(payload);
    }
  });
};


const useVerifyCustomer = () => {
  return useMutation(async (id: string) => {
    return AdminCustomerUserModel.VerifyUserAccount(id)
  })
}

/** Super Users */

const useGetSuperUsers = () => {
  return useQuery(['super-users'], () => {
    return AdminSuperUserModel.GetUsers();
  });
};

export {
  useGetCustomerUsers,
  useFindCustomerUserById,
  useUpdateCustomer,
  useDeleteCustomer,
  useCreateCustomer,
  useGetSuperUsers,
  useGetUserById,
  useVerifyCustomer
};
