import { Table } from '@mantine/core';

const SubmissionsListTable = (props: any) => {
  return (
    <Table
      border={1}
      highlightOnHover
      verticalSpacing="xs"
      style={{ whiteSpace: 'nowrap', border: '1px solid #dfe1e2' }}
      className="submission-table"
    >
      {props.children}
    </Table>
  );
};

export default SubmissionsListTable;
