import { Textarea } from '@mantine/core';
import { useEffect, useMemo } from 'react';
import { FormFieldProps } from './types';

export default function InputTextArea(props: FormFieldProps) {
  const disable = useMemo(() => {
    if (props.columnConfig?.dependencyColumn) {
      const columnName = props.columnConfig.dependencyColumn.columnName;
      const tableName = props.columnConfig.dependencyColumn.tableName;
      const value = (props.form.values[tableName][columnName] ?? '').replace(/[{}]/g, '');

      return value === props.columnConfig?.dependencyColumn.value;
    } else {
      return true;
    }
  }, [props.form.values]);

  useEffect(() => {
    if (!disable) {
      props.form.setFieldValue(`${props.tableName}.${props.columnName}`, '');
    }
  }, [disable]);

  return disable ? (
    <Textarea
      {...props.form.getInputProps(`${props.tableName}.${props.columnName}`)}
      name={props.columnName}
      p={8}
      label={props.description}
      required={props.required}
      placeholder={props.columnConfig.placeHolder}
    />
  ) : (
    <></>
  );
}
