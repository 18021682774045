export default function Content() {
  const content = `
  
  <style>
  
  h1,
  h3,
  h3,
  h4,
  h5,
  h6 {
     font-family: "Nunito", sans-serif !important;
  }
  * {
     font-family: "Nunito", sans-serif !important;
  }
  input.primary-button {
     padding-left: 20px;
     padding-right: 20px;
     background-position: right;
     background-repeat: no-repeat;
     background-color: #ff303a;
     text-align: center;
  }
  #ButtonNext1 {
     color: #fff;
  }
  .banner {
     padding: 50px 0;
  }
  .button-area {
     margin-top: 2em;
  }
  .banner {
     max-width: 1600px;
     margin: 0 auto;
     padding: 100px 0;
  }
  banner.h1 {
     font-size: 2em;
     font-weight: 300;
     color: #fff;
     line-height: 1.3;
     margin: 0 0 1em;
     letter-spacing: 0.01em;
  }

  input.primary-button,
  input.secondary-button {
     height: 44px;
     line-height: 1.3;
     margin: 0;
     outline: 0;
     border: 0;
     /* border-radius: 44px; */
     font-size: 1em;
     font-family: "Nunito", sans-serif;
     /* font-family: "Lato", Arial, sans-serif; */
     font-weight: 600;
     text-transform: uppercase;
     color: #181616;
     -webkit-transition: background-color 0.2s ease-out;
     -moz-transition: background-color 0.2s ease-out;
     transition: background-color 0.2s ease-out;
     -webkit-appearance: button;
     cursor: pointer;
     white-space: normal;
     text-align: left;
  }
  .banner-wrapper {
     /* background: #012b33; */
     border-bottom: 8px solid #48a8c0;
     padding: 0 20px;
  }

  .banner p {
     font-size: 1em;
     color: #000;
     margin-bottom: 0.25em;
  }
  .banner h1,
  .banner p {
     font-size: 1.6em;
     color: #000;
     margin-bottom: 0.25em;
  }
  body {
     position: relative;
     line-height: 1;
     font-family: "Nunito", sans-serif;
     font-size: 100%;
     font-weight: 300;
     margin: 0;
     -webkit-font-smoothing: antialiased;
     -webkit-text-size-adjust: 100%;
  }
  .logo-wrap {
     clear: both;
     height: 110px;
     padding: 10px;
  }
  .site-branding {
     float: left;
     height: 100px;
  }
  .site-logo {
     height: 100px;
  }
  .tac-logo-wrapper {
     float: right;
     /* margin: 0;
     position: absolute;
     top: 50%;
     left: 50%;
     transform: translate(-50%, -50%); */
  }
  .tac-logo {
     width: 350px;
  }
  .footer {
     position: fixed;
     left: 0;
     bottom: 0;
     width: 100%;
     background-color: black;
     color: #fff;
     text-align: center;
     font-size: 16px;
  }
  .footer a {
     color: #fff;
     text-decoration: none;
  }
  .shadow {
     box-shadow: 0 5px 5px -5px #333;
  }

  ul {
    list-style: none;
  }

  #ctl00_wrapper {
    padding: 20px
  }

  #table {
    border-collapse: collapse;
  }

  #table td, #table th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }

  .containerNote h4,
  .containerNote p {
      display: inline;
      vertical-align: top;
  }

  .content-wrapper.menu-content {
    margin-top: 3rem;
    margin-bottom: 5rem;
  }
  .notice-menu {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
    padding: 0;
  }

  .notice-menu li a {
    text-decoration: none;
    color: inherit;
    font-size: 1.25rem;
    font-weight: 600;
    text-transform: uppercase;
  }

  .notice-menu li a:hover {
    text-decoration: underline;
  }

  .isDisabled {
    color: currentColor;
    cursor: not-allowed;
    opacity: 0.5;
    text-decoration: none;
  }
                    </style>
                    <div id="ctl00_wrapper">
                      <div class = "content-wrapper">
                        <div class = "content">
                          <h2>SIMS CLASS ACTION – SETTLEMENT DISTRIBUTION SCHEME </h2> 
                        </div>
                      </div>
                      <div class="content-wrapper">
                        <div class="content">
                          <table id="table">
                              <tr>
                                <td>
                                    Proceedings name:
                                </td>
                                <td>
                                    Peter Hermann Eckardt -v- Sims Limited (<strong>Sims class action</strong>)
                                </td>
                              </tr>
                              <tr>
                                <td>
                                    Proceedings number:
                                </td>
                                <td>
                                    NSD220/2019
                                </td>
                              </tr>
                              <tr>
                                <td>
                                    Court:
                                </td>
                                <td>
                                    Federal Court of Australia
                                </td>
                              </tr>
                              <tr>
                                <td>
                                    Lawyers:
                                </td>
                                <td>
                                    William Roberts Lawyers (<strong>William Roberts</strong>)
                                </td>
                              </tr>
                              <tr>
                                <td>
                                    Litigation Funder(s):
                                </td>
                                <td>
                                    ICP Capital Pty Ltd and Investor Claim Partner Pty Ltd 
                                </td>
                              </tr>
                          </table>
                        </div>
                      </div>
                      <div class="content-wrapper menu-content">
                        <h3>Menu</h3>
                        <ul class="notice-menu">
                        <li>
                        \u{1F517}
                        <a href="#preliminary-notice"> Preliminary Notice</a>
                          </li>
                          <li>
                          \u{1F517}
                          <a href="#estimated-notice">Initial Assessment Notice</a>
                          </li>
                          <li>
                          \u{1F517}
                          <a href="#final-notice">FINAL DISTRIBUTION NOTICE</a>
                          </li>
                        </ul>
                      </div>
                      <hr/>
                      <div class="content-wrapper">
                        <div class="content">
                          <article class="standard">
                              <h3>PRELIMINARY NOTICE </h3>
                              <ul>
                                <li>
                                    <p>1. This information and its related Parts constitute the Preliminary Notice for the purpose of section 3 of the Court approved Settlement Distribution Scheme (<strong>SDS</strong>), being for the confirmation of your Trade Data, and other relevant information required for processing your claim and determining your entitlement in accordance with the SDS.
                                    <p/>
                                </li>
                                <li>
                                    <p>2. PART A sets out the information we currently hold for you (<strong>Contact Details</strong>). <b>Note:</b> the contact person may be different to the Registered Group Member person or entity.</p>
                                </li>
                                <li>
                                    <p>
                                      3. PART B sets out the information we currently hold in relation to the Registered Group Member (<strong>RGM Details</strong>).
                                      </p>
                                </li>
                                <li>
                                    <p>4. PART C sets out the share trading data we currently hold in relation to the RGM’s claim (<strong>Trade Data</strong>).
                                    </p>
                                </li>
                                <li>
                                    <p>5. (collectively Parts A, B and C form the <strong>Registration Information</strong>).
                                    </p>
                                </li>
                              </ul>
                            </article>
                        </div>
                      </div>
                      <div class="content-wrapper">
                        <div class="content">
                          <article class="standard">
                              <h3>Accuracy of Registration Information  </h3>
                              <ul>
                                <li>
                                    <p>6. Contact Details comprise of the following information:</p>
                                    <p>
                                    <ul>
                                      <li>(a)	First Name;</li>
                                      <li>(b)	Last Name;</li>
                                      <li>(c)	Address (if provided);</li>
                                      <li>(d)	Telephone number; and</li>
                                      <li>(e)	Email address;</li>
                                    </ul>
                                    </p>
                                </li>
                                <li>
                                    <p>7. RGM Details comprise of the following information for each Registered Group Member (RGM):</p>
                                    <p>
                                    <ul>
                                      <li>(a)	name of the RGM;</li>
                                      <li>(b)	If an Australian registered business entity, ABN/ACN  (as applicable);</li>
                                      <li>(c)	Trading Account SRN/HIN;</li>
                                      <li>(d)	Name of entity as recorded in the SGM share registry;</li>
                                      <li>(e)	Bank account details to facilitate payment to you of your entitlement under the SDS;</li>
                                      <li>(f)	Tax file number (if Australian Resident). See below for further details; and</li>
                                      <li>(g)	For natural person (individual) RGMs - proof of identity (either a copy of your Australian drivers licence, identity card or passport). See below for further details.</li>
                                    </ul>
                                    </p>
                                </li>
                                <li>
                                    <p>8. The Trade Data required for the purpose of the SDS comprises of the following information for each RGM:</p>
                                    <p>
                                    <ul>
                                      <li>(a)	SRN/HIN;</li>
                                      <li>(b)	number of Sims shares (<strong>Shares</strong>) as at the opening of trading on the ASX on 23 July 2014;</li>
                                      <li>
                                          (c)	details of Share acquisitions and Share disposal transactions between 23 July 2014 and 19 February 2016 (inclusive), being:  
                                          <ul>
                                            <li>(i)	the date when each transaction occurred; and
                                            </li>
                                            <li>(ii) the number of Shares acquired or disposed in each transaction; and
                                            </li>
                                            <li>(iii) the price per share;
                                            </li>
                                            <li>(iv) total amount (AUD); and</li>
                                          </ul>
                                      </li>
                                      <li>(d)	balance of the number of Shares following each transaction.</li>
                                    </ul>
                                    </p>
                                </li>
                              </ul>
                            </article>
                        </div>
                      </div>

                      <div class="content-wrapper">
                        <div class="content containerNote">
                          <h4>Note:</h4> <p>We require the information in Parts A, B and C as detailed above. We specifically bring your attention to our request for you to provide bank account details for payment of any entitlement under the SDS, for natural persons - proof of identity (showing your date of birth), and Tax File Number for Australian residents.
                          </p>
                        </div>
                      </div>

                      <div class="content-wrapper">
                        <div class="content">
                          <article class="standard">
                            <h3>If the pre-populated Registration Information is accurate</h3>
                          </article>
                        </div>
                      </div>

                      <div class="content-wrapper">
                        <div class="content">
                          <article class="standard">
                            <p>
                              <ul>
                                <li>9. We have pre-populated some Registration Information from details previously provided to the litigation funder. If such pre-populated Registration Information is correct, you do not have to inform us that the information we hold is correct. <strong>Note:</strong> You still need to provide Registration Information that is not pre-populated. See below.</li>
                              </ul>
                            </p>
                          </article>
                        </div>
                      </div>

                      <div class="content-wrapper">
                        <div class="content">
                          <article class="standard">
                              <h3>IF THE REGISTRATION INFORMATION IS INACCURATE AND INCOMPLETE</h3>
                              
                              <p>
                                <ul>
                                  <li>10.  If you believe any of the Registration Information in PARTS A, B and C that we have pre-populated is inaccurate because it does not contain the required information as requested in Parts A, B and C, you must inform us in accordance with the directions specified  below of any inaccurate information by 30 June 2023 (<strong>Preliminary Response Date</strong>). See paragraphs 25, 26 and 27 of this notice or section 11 of the SDS for the requirements for notice to be given for the purpose of the SDS.
                                  </li>
                                  <li><p>11. If notification of inaccurate Contact Details, RGM Details or Trade Data is not received as directed in accordance with this notice by the Preliminary Response Date, the Contact Details, RGM Details and Trade Data as we hold will be deemed to be complete and correct and will be used moving forward, including to calculate your entitlements under the SDS.</p></li>
                                  
                                    <h4>Correcting inaccurate information</h4>
                                  <li>
                                    <p>12.  If you seek to correct the Registration Information we have pre-populated which is from information we hold, we ask that you provide the correct information together with documentation that proves the inaccuracy in the notice, by the Preliminary Response Date, to the address details at paragraph 22 of this notice, or under the Review and Verify section of this notice by selecting “not correct” and providing details of the amendment(s) together with uploading the supporting documents.</p>
                                  </li>
                                    <li>
                                        <p>13.	If notification of inaccurate Registration Information is received as directed in this notice, in accordance with clause 3.3 of the SDS, we will: 
                                          <ul>
                                            <li>(a) consider the notification and the supporting documents supplied as proof of such inaccuracy, and, at our sole discretion, will cause changes to be made to the Contact Details, RGM Details and/or Trade Data where appropriate; and 
                                            </li>
                                            <li>(b) direct that a notification be provided to you of:  
                                              <ul>
                                                <li>(i) any updated Contact Details, RGM Details and/or Trade Data as determined by us; or</li>
                                                <li>(ii) our determination that no alteration to the Contact Details, RGM Details and/or Trade Data is warranted, (the <strong>Data Determination</strong>).</li>
                                              </ul>                                          
                                            </li>
                                          </ul>
                                        </p>
                                    </li>
                                    <li>
                                      <p>14.	In accordance with clause 3.4 of the SDS, any Data Determination is final and binding unless, prior to the expiry of 14 days after notice is given of the Data Determination, you make an application (at your cost) to the Federal Court of Australia to review the Data Determination.  Any such application must be served on William Roberts to the address details provided in paragraph 24 of this notice.</p>
                                    </li>

                                    <h4>Providing information that is incomplete</h4>
                                    <li>
                                    <p>15. Incomplete information (which has not been pre-populated) can be provided by completing the available fields in Parts A, B and C</p>
                                  </li>

                                </ul>
                              </p>
                          </article>
                        </div>
                      </div>
                      <div class="content-wrapper">
                        <div class="content">
                            <article class="standard">
                                
                                <h3>Why is a Tax file number required if you are an Australian resident</h3>
                                    <ul>
                                        <li>
                                          <p>16.	The settlement funds have been and will continue to be invested in an interest earning account as required under the SDS. This interest income will be added to and form part of the settlement fund that may be distributed to you and considered as taxable income.  Amounts for submission to the ATO will be withheld, as required. We require your TFN for tax compliance purposes, including for completion and submission of tax returns with the ATO on behalf of the SDS (Trust). Your tax file number can be securely provided in this portal OR [for large data sets] – by emailing <a href="mailto:sims@theadvisoryco.com">sims@theadvisoryco.com</a>. TFNs will only be used for this limited purpose and permanently deleted upon completion of this part of the SDS</p>
                                        </li>
                                        <li>
                                          <p>17.	You should seek the independent advice of your taxation, financial or accounting advisers regarding the withheld amounts and/or the impact on your tax affairs by any entitlements or payments in accordance with the SDS.</p>
                                        </li>
                                    </ul>
                                </div>
                            </article>
                        </div>
                        <div class="content-wrapper">
                          <div class="content">
                            <article class="standard">
                              <h3>Copy of identification document </h3>
                              <ul>
                                  <li>
                                    <p>18.	For natural persons (individual) RGMs, we ask that you provide a copy of your Australian drivers licence, identity card or passport in order to confirm your identity via this portal OR [for large data sets] by reply email (secure link to cloud storage will be fine).  Please ensure that the copy you provide clearly displays your date of birth, which is important for tax compliance purposes.  The copy can be provided in the following file formats: PDF, JPEG or PNG.</p>
                                  </li>
                                  <li>
                                    <p style="background: light-green">19.	For security reasons, please do not send the additional information other than via the methods set out in the paragraph above. </p>
                                  </li>
                                  <li>
                                    <p>20.	[William Roberts is committed to complying with the Privacy Act 1988 (Cth).  William Roberts uses a variety of physical and electronic security measures to keep your personal information secure from misuse, interference, loss or unauthorised access, use or disclosure.  All William Roberts employees are also bound to keep your personal information secure and treat it as confidential.  For full details, please visit <a href="https://www.williamroberts.com.au/Privacy-Policy">https://www.williamroberts.com.au/Privacy-Policy</a>.]</p>
                                  </li>
                                  <li>
                                    <p style="background: light-green">21.	TAC  is committed to complying with the Privacy Act 1988 (Cth).  TAC uses a variety of physical and electronic security measures to keep your personal information secure from misuse, interference, loss or unauthorised access, use or disclosure. For full details, please visit (TAC <a href="https://theadvisoryco.com/privacy-policy">Privacy Policy</a>). </p>
                                  </li>
                              </ul>
                            </article>
                          </div>
                        </div>
                        <div class="content-wrapper">
                        <div class="content">
                            <article class="standard">
                                <h3>NOTIFICATIONS AND TIMING</h3>
                                    <ul>
                                        <li>
                                          <p>22.	Unless specified otherwise, any formal notification from you to us for the purposes of this Notice, including the Preliminary Notice portion, is to be provided as follows:</p>
                                          <p style="text-align: left;padding-left: 20px">By email  <a href="mailto:sims@theadvisoryco.com">sims@theadvisoryco.com</a></p>
                                          <p style="text-align: left;padding-left: 20px">By post  PO Box 1128  Mitcham North  VIC  3132</p>
                                          <p style="text-align: left;padding-left: 20px">By telephone  (03) 8821 9330</p>
                                        </li>
                                        <li>
                                          <p>23.	Queries or questions concerning the information requested in this portal should be directed to The Advisory Company in the first instance using the details above. The Advisory Company will refer queries of a legal nature to William Roberts Lawyers, as required and a response will be provided accordingly.</p>
                                        </li>
                                        <li>
                                          <p>24.	For queries or questions relating to the operation of SDS and this Preliminary Notice you can contact William Roberts as follows:</p>
                                          <p style="padding-left: 10px">RE:  Sims Class action</p>
                                          <p style="padding-left: 10px">By Email:  <a href="mailto:WR-Sims@williamroberts.com.au">WR-Sims@williamroberts.com.au</a></p>
                                          <p style="padding-left: 10px">By telephone:  (02) 9552 2111</p>
                                        </li>
                                        <li>
                                          <p>25.	Please note that pursuant to clause 11 of the SDS, any notice or document to be given (or delivered) pursuant to the SDS shall be deemed to be given (or delivered) and received for all purposes associated with the SDS if it is:</p>
                                          <p>(a)	addressed to the person to whom it is to be given; and</p>
                                          <p>(b)	either:</p>
                                          <p style="padding-left: 20px">(i)	delivered, or sent by pre-paid mail, to that person's postal address (being, in respect of any RGM, the most resent postal address recorded in the Claimant Database);</p>
                                          <p style="padding-left: 20px">(ii)	sent by fax to that person's fax number (being, in respect of any RGM, the most recent fax number recorded in the Claimant Database) and the machine from which it is sent produces a report that states that it was sent in full; or</p>
                                          <p style="padding-left: 20px">(iii)	sent by email to that person's email address (being, in respect of any RGM, the most resent email address recorded in the Claimant Database) and a server through which it is transmitted produces a report that states that the email has been sent to the inbox of the specified email address.</p>
                                        </li>
                                        <li>
                                          <p>26.	A notice or document that complies with paragraph 25 will be deemed to have been given (or delivered) and received:</p>
                                          <p>(a)	if it was sent by mail to an addressee in Australia, two (2) clear business days after being sent;</p>
                                          <p>(b)	if it is sent to an addressee oversees, five (5) clear business days after being sent;</p>
                                          <p>(c)	if it is delivered or sent by fax, at the time stated on the report that is produced by the machine from which it is sent; or</p>
                                          <p>(d)	if it is sent by email, at the time it is sent.</p>
                                        </li>
                                        <li>
                                          <p>27.	Where an RGM is not a natural person and where one person has been nominated as the contact in respect of several RGMs, it is sufficient for the purpose of giving notice that any of the matters in paragraph 25 are complied with in relation to that nominated person.</p>
                                        </li>
                                    </ul>
                                </div>
                            </article>
                        </div>
                    </div>  
                  </div>
`;

  return <div style={{ width: '100%' }} dangerouslySetInnerHTML={{ __html: content }}></div>;
}
