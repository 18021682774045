import LocalStorage from '../helpers/local-storage';
import AccessDenied from './common/access-denied';
import { Layout } from '../Layout';
import { Header } from './Header';

const AdminProtectedRoute = () => {
  const isAuth = LocalStorage.GetIsAuthenticated();
  const hasRoleId = LocalStorage.GetRoleId();
  if (!isAuth || !hasRoleId) {
    return <AccessDenied />;
  }
  return <Layout header={<Header />} />;
};

export default AdminProtectedRoute;
