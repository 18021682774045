import { Alert as MantineAlert, AlertProps, Button, SimpleGrid } from '@mantine/core';
import { Link } from 'react-router-dom';

interface IAlertProps extends Partial<AlertProps> {
  message: string;
  title?: string;
  code?: number;
}
const Alert = (props: IAlertProps) => {
  const { message, color = 'red', title = 'An Error Occured', code, ...alertProps } = props;
  return (
    <MantineAlert color={color} title={title} {...alertProps}>
      <SimpleGrid>
        {message}
        {code && (
          <Button component={Link} to="/verify" color={'red'}>
            Resend Verification Code
          </Button>
        )}
      </SimpleGrid>
    </MantineAlert>
  );
};

export default Alert;
