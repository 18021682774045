import { ProjectPage } from "../project/pages/types"

export const ContainerMainStyles = { 
  width: '100% !important',
  maxWidth: '100%',
  paddingLeft: '0',
  paddingRight: '0',
  marginLeft: '0',
  marginRight: '0'
}

export const GroupStyle = { height: '100%' }

export const FlexDirection = (passwordPageData: ProjectPage | undefined) => {
  return passwordPageData?.content ? 'column': 'row'
}

export const DivContent = { width: '100%' }

export const GroupContent = {
  height: '100%'
}