import { Group, Paper } from '@mantine/core';
import Alert from '../../../component/common/alert';
import OverlayLoader from '../../../component/common/loading-overlay';
import { Footer } from '../../../component/Footer';
import Header from '../../form-field/component/header';
import { useGetUserSubmissions } from '../hooks';
import UserSubmissionTable from './table';

const UserSubmissionList = () => {
  const { isLoading, data, isError, error } = useGetUserSubmissions();

  /**
   * @to-do
   * implement global layout for common header & footer
   */
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        minWidth: 'max-content'
      }}
    >
      <Header />
      <Group position="center" sx={{ flex: 1 }} pt={'1rem'} pb={'1rem'}>
        <Paper withBorder shadow={'lg'} p={'1rem'} sx={{maxWidth: '100vw'}}>
          {isLoading && <OverlayLoader visible={isLoading} />}
          {isError && <Alert message={error.message} />}
          {data && <UserSubmissionTable data={data} />}
        </Paper>
      </Group>
      {data && <Footer />}
    </div>
  );
};
export default UserSubmissionList;
