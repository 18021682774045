import { Title } from '@mantine/core';
import { FormFieldProps } from './types';

export default function Subheading({ hideLabel = false, ...props }: FormFieldProps) {
  return (
    <Title order={3} align="center">
      {props.description}
    </Title>
  );
}
