import { Card, Group } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { useMediaQuery } from '@mantine/hooks';
import FormField from '../../../component/common/form-field-type/form-field-type';
import { useGetPage } from '../../project/pages/hooks';
import { IProjectTableColumn, IProjectTableConfig } from '../../project/types';
import { useLocation } from 'react-router-dom';
import LocalStorage from '../../../helpers/local-storage';
import { useMemo } from 'react';
import { DISTRIBUTION_NOTICE } from '../../../utils/helper';

interface IFormProjectTableColumnProps {
  columns: IProjectTableColumn[];
  form: UseFormReturnType<any>;
  tableConfig?: IProjectTableConfig;
  tableName: string;
}

const FormProjectTableColumn = ({ columns, form, tableName, tableConfig }: IFormProjectTableColumnProps) => {
  const smallScreen = useMediaQuery('(max-width: 800px)');

  const isAdmin = LocalStorage.IsAdmin();
  const location = useLocation();
  const isNewForm = location.pathname === '/form';

  const disableFormConditions = !isAdmin && !isNewForm && tableConfig;
  const conditionalProps = disableFormConditions ? { nonEditableFilledForm: tableConfig.nonEditableFilledForm } : {};
  const { data } = useGetPage('index');

  const projectTableVisible = useMemo(() => {
    if (!DISTRIBUTION_NOTICE.includes(tableName)) {
      return true;
    }

    const visibleToAll = tableConfig?.visibleToAll;
    if (visibleToAll) {
      return true;
    }

    if (isAdmin && !visibleToAll) {
      return true;
    }

    return false;
  }, [tableConfig, isAdmin, tableName]);

  if (!projectTableVisible) {
    return null;
  }

  return (
    <Group direction="column" position="center">
      {columns.length > 0 && (
        <Card
          withBorder
          shadow={'sm'}
          sx={{
            backgroundColor: '#f1f1f1',
            borderColor: '#dedede',
            minWidth: smallScreen ? '' : '60rem',
            maxWidth: smallScreen ? '20rem' : '',
          }}
          px="xl"
        >
          {columns
            .sort((a, b) => a.sortOrder - b.sortOrder)
            .map((column: IProjectTableColumn) => {
              if (column.columnConfig?.updateTableName && data?.content) {
                const { param } = column.columnConfig.updateTableName;
                const elementId = param.replace(/{|}/g, '');
                const parser = new DOMParser();
                const doc = parser.parseFromString(data?.content, 'text/html');
                const spanElement = doc.getElementById(elementId);
                if (spanElement) {
                  const targetValue = spanElement.textContent;
                  if (targetValue) {
                    column.description = column.description.replace(param, targetValue);
                  }
                }
              }

              if (column.columnName === 'bank_name') {
                return (
                  <div key={column.columnName}>
                    <div className="mantine-TextInput-root mantine-11gd8l3">
                      <label className="mantine-10efxhd mantine-TextInput-label">
                        <strong>Bank Details</strong>
                      </label>
                    </div>
                    <FormField
                      {...conditionalProps}
                      key={`${tableName}-${column.columnName}`}
                      {...column}
                      form={form}
                      tableName={tableName}
                    />
                  </div>
                );
              } else if (column.columnName === 'fn_name_of_rgm') {
                return (
                  <div key={column.columnName}>
                    <div className="mantine-TextInput-root mantine-11gd8l3">
                      <label className="mantine-10efxhd mantine-TextInput-label">
                        <strong>RGM Details</strong>
                      </label>
                    </div>
                    <FormField
                      {...conditionalProps}
                      key={`${tableName}-${column.columnName}`}
                      {...column}
                      form={form}
                      tableName={tableName}
                    />
                  </div>
                );
              } else {
                return (
                  <div key={column.columnName}>
                    <FormField
                      {...conditionalProps}
                      key={`${tableName}-${column.columnName}`}
                      {...column}
                      form={form}
                      tableName={tableName}
                    />
                  </div>
                );
              }
            })}
        </Card>
      )}
    </Group>
  );
};

export default FormProjectTableColumn;
