import CoreConfig from '../../config/core';
import CoreService from '../../services/core';
import CatchError from '../../services/error';
import { ICoreServiceProps, ICoreServiceResponse } from '../../services/types';
import { IProjectTableWithChild } from '../project/types';
import { FileDetails } from './types';

class SubmissionModel extends CoreService {
  constructor(path: string, config: ICoreServiceProps) {
    super(path, config);
    this.path = path;
  }

  async GetProjectSubmissionForm(): Promise<IProjectTableWithChild> {
    try {
      const result: ICoreServiceResponse = await this.axios.get(`${this.path}/form`);
      return result.data.data;
    } catch (e: any) {
      throw CatchError.Negotiate(e);
    }
  }

  async SaveSubmission(params: any): Promise<any> {
    try {
      const result: ICoreServiceResponse = await this.Post(params);
      return result.data.data;
    } catch (e: any) {
      throw CatchError.Negotiate(e);
    }
  }

  async GetUserSubmissions() {
    try {
      const result: ICoreServiceResponse = await this.axios.get(`/me/submissions`);
      return result.data.data;
    } catch (e: any) {
      throw CatchError.Negotiate(e);
    }
  }

  async GetUserSubmission(id: string) {
    try {
      const result: ICoreServiceResponse = await this.axios.get(`/me/submissions/${id}`);
      return result.data.data;
    } catch (e: any) {
      throw CatchError.Negotiate(e);
    }
  }
  async UpdateMySubmission(id: string, bodyParams: any) {
    try {
      const result: ICoreServiceResponse = await this.axios.patch(`/me/submissions/${id}`, bodyParams);
      return result.data.data;
    } catch (e: any) {
      throw CatchError.Negotiate(e);
    }
  }

  async UploadFile(file: File) {
    try {
      let formdata = new FormData();
      formdata.append('file', file, '/path/to/file');
      const result: ICoreServiceResponse = await this.axios.post(`submissions/upload-file`, formdata);
      return result.data.data;
    } catch (e: any) {
      throw CatchError.Negotiate(e);
    }
  }

  async UploadFileV2(file: File, fileDetails: FileDetails) {
    try {
      let formdata = new FormData();
      formdata.append('file', file, '/path/to/file');
      formdata.set('fileName', fileDetails.fileName);
      formdata.set('groupKey', fileDetails.groupKey);
      formdata.set('submissionId', fileDetails.submissionId);
      const result: ICoreServiceResponse = await this.axios.post(`submissions/upload-file-v2`, formdata);
      return result.data.data;
    } catch (e: any) {
      throw CatchError.Negotiate(e);
    }
  }

  async GetUploads(submissionId: string, groupKey: string) {
    try {
      const queryString = new URLSearchParams({
        groupKey,
      }).toString();
      const result: ICoreServiceResponse = await this.axios.get(`submissions/${submissionId}/uploads?${queryString}`);
      return result.data.data;
    } catch (e: any) {
      throw CatchError.Negotiate(e);
    }
  }

  async RemoveFile(submissionId: string, uploadId: string) {
    try {
      const result: ICoreServiceResponse = await this.axios.delete(`submissions/${submissionId}/uploads/${uploadId}`);
      return result.data.data;
    } catch (e: any) {
      throw CatchError.Negotiate(e);
    }
  }

  async GetFile(id: string) {
    try {
      const result: ICoreServiceResponse = await this.axios.get(`submissions/get-file/${id}`);
      return result.data.data;
    } catch (e: any) {
      throw CatchError.Negotiate(e);
    }
  }
}
export default new SubmissionModel('/submissions', CoreConfig);
