import { useQuery, useMutation } from 'react-query';
import { IProject } from '../../pages/project/types';
import { IErrorObject } from '../../services/types';
import AdminProjectModel from './AdminProject.model';

const useGetProjectDetails = () => {
  return useQuery(['adminGetProjectDetails'], () => {
    return AdminProjectModel.GetProjectDetails();
  });
};

const useUpdateProject = () => {
  return useMutation<Partial<IProject>, IErrorObject, IProject, Partial<IProject>>(async (project: IProject) => {
    return AdminProjectModel.UpdateProjectDetails(project);
  }, {});
};

export { useGetProjectDetails, useUpdateProject };
