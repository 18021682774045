import CoreService from '../../services/core';
import { ICoreServiceProps } from '../../services/types';
import CoreConfig from '../../config/core';
import CatchError from '../../services/error';
import { ICreateUserPayload, IUserPayload } from './types';

class AdminSuperUserModel extends CoreService {
  constructor(path: string, config: ICoreServiceProps) {
    super(path, config);
    this.path = path;
  }

  async GetUsers() {
    try {
      const result = await this.axios.get(this.path);
      return result.data.data;
    } catch (e: any) {
      throw CatchError.Negotiate(e);
    }
  }

  async FindUserById(id: string) {
    try {
      const result = await this.axios.get(`${this.path}/${id}`);
      return result.data.data;
    } catch (e: any) {
      throw CatchError.Negotiate(e);
    }
  }

  async UpdateSuperUser(payload: IUserPayload) {
    try {
      const result = await this.axios.put(`${this.path}/${payload.id}`, payload.request);
      return result.data.data;
    } catch (e: any) {
      throw CatchError.Negotiate(e);
    }
  }

  async CreateSuperUser(payload: ICreateUserPayload) {
    try {
      const result = await this.axios.post(this.path, payload);
      return result.data.data;
    } catch (e: any) {
      throw CatchError.Negotiate(e);
    }
  }

  async DeleteSuperUser(id: string) {
    try {
      const result = await this.axios.delete(`${this.path}/${id}`);
      return result.data.data;
    } catch (e: any) {
      throw CatchError.Negotiate(e);
    }
  }
}

export default new AdminSuperUserModel('/admin/super-users', CoreConfig);
