import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ActionIcon, Container } from '@mantine/core';
import { showNotification, updateNotification } from '@mantine/notifications';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Alert from '../../../component/common/alert';
import { NAV } from '../../../config/navigation';
import { useGetSubmissionForm } from '../../../pages/form-field/hooks';
import { useGetSubmission, useUpdateSubmission } from '../hook';
import EditForm from './components/edit-form';
import { CheckIfSims } from '../../../utils/helper';
import Content from '../../../component/common/content';
import { ScrollToTop } from '../../../component/common/scroll-to-top';

const ViewMySubmission = () => {
  const params = useParams();
  const { data: editData, isFetchedAfterMount } = useGetSubmission(params.id || '');
  const processUpdateHook = useUpdateSubmission(params.id || '');
  const { error, data: initialForm } = useGetSubmissionForm();
  const navigate = useNavigate();

  useEffect(() => {
    if (processUpdateHook.isSuccess) {
      updateNotification({
        id: 'load-data',
        color: 'teal',
        title: 'Edit Submission',
        message: 'Submission was updated......',
        icon: <FontAwesomeIcon icon={faCheck} />,
        autoClose: 1500,
        onClose: () => {
          navigate(NAV.ADMIN);
        },
      });
    }
  }, [processUpdateHook.isSuccess, navigate]);

  const handleSave = (value: any) => {
    showNotification({
      id: 'load-data',
      loading: true,
      title: 'Updating Submission',
      message: 'Saving.....',
      autoClose: false,
      disallowClose: true,
    });

    processUpdateHook.mutate(value);
  };
  if (error) {
    return <Alert message={error.message} />;
  }
  const isSims = CheckIfSims();

  return (
    <>
      <Container fluid>
        {isSims && <Content />}
        {initialForm && editData && isFetchedAfterMount && (
          <div>
            <EditForm
              data={initialForm}
              editData={editData}
              onSaveEdit={handleSave}
              loadingSubmission={processUpdateHook.isLoading}
            />
          </div>
        )}
      </Container>
      <ScrollToTop />
    </>
  );
};

export default ViewMySubmission;
