import { faKey, faLock, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Checkbox, Group, Paper, Stack, Text, TextInput, Title } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { useMediaQuery } from '@mantine/hooks';
import { useEffect } from 'react';
import { UseMutationResult } from 'react-query';
import { Link, useNavigate } from 'react-router-dom';
import Alert from '../../../../component/common/alert';
import OverlayLoader from '../../../../component/common/loading-overlay';
import { NAV } from '../../../../config/navigation';
import LocalStorage from '../../../../helpers/local-storage';
import { IErrorObject } from '../../../../services/types';
import { IUserAdminProfile, IUserProfile } from '../../../user/types';
import { formPaperHeadingStyle, formPaperStyle, formPaperStyleLarge, linkStyle } from '../../styles';
import { ILoginFormProps } from '../../types';
import { CheckIfSims } from '../../../../utils/helper';
interface ILoginFormCProps {
  formHook: UseFormReturnType<ILoginFormProps>;
  loginHook: UseMutationResult<
    IUserProfile | IUserAdminProfile,
    IErrorObject,
    ILoginFormProps,
    IUserProfile | IUserAdminProfile
  >;
}
export function LoginForm({ formHook, loginHook }: ILoginFormCProps) {
  const { onSubmit, getInputProps } = formHook;
  const navigate = useNavigate();
  const { mutate, isError, error, isLoading, isSuccess, data } = loginHook;
  const largeScreen = useMediaQuery('(min-width: 800px)');
  //----------------------------------------------------------------------------------
  const OverrideSubmitHandler = (values: ILoginFormProps) => {
    const { user, ...rest } = values;
    const lowerCaseUser = user.toLocaleLowerCase();
    mutate({ user: lowerCaseUser, ...rest });
  };
  const isSims = CheckIfSims();

  useEffect(() => {
    if (data && isSuccess) {
      LocalStorage.SetIsAuthenticated(true);
      LocalStorage.SetUserType('customer');
      LocalStorage.Set('email', data.email);

      /**
       * check if login method returns a roleId and saved in local storage
       * if roleId localstorage is present redirect to project-admin page
       */

      if ((data as IUserAdminProfile).systemUserId) {
        const _user = data as IUserAdminProfile;
        const roleId = _user.systemUserRole.roleId;
        LocalStorage.SetRoleId(roleId);
        return navigate(NAV.ADMIN);
      }

      navigate(NAV.USER_SUBMISSIONS_LIST);
    }
  }, [data, isSuccess, navigate]);

  //----------------------------------------------------------------------------------
  
  return (
    <Paper withBorder shadow={'md'} sx={largeScreen ? formPaperStyleLarge : formPaperStyle}>
      <OverlayLoader visible={isLoading} />
      <Title align="center" order={2} styles={formPaperHeadingStyle}>
        { isSims ? 'Log in' : 'Account Login' }
      </Title>

      {isError && <Alert mt="1rem" message={error?.error?.message} />}

      <form onSubmit={onSubmit(values => OverrideSubmitHandler(values))}>
        <Stack spacing={10} mt="3rem">
          <TextInput
            label="Email"
            placeholder="login email"
            {...getInputProps('user')}
            icon={<FontAwesomeIcon icon={faUser} />}
          />
          <TextInput
            mt="sm"
            type="password"
            label="Password"
            autoComplete="false"
            placeholder="password"
            icon={<FontAwesomeIcon icon={faLock} />}
            {...getInputProps('password')}
          />
          <Text<typeof Link> underline size="xs" sx={linkStyle} component={Link} to={NAV.RESET_PASSWORD}>
            Forgot Password?
          </Text>
          <Group position="apart" mt="4rem">
            <Checkbox label="Remember me" />
            <Button type="submit" px={50}>
              Sign in
            </Button>
          </Group>
        </Stack>

        <Text size="sm" mt={'lg'}>
          <FontAwesomeIcon icon={faKey} style={{ paddingRight: '0.5rem' }} />
          Don't have an account?
          <div>
            <Text<typeof Link> underline size="sm" pl={'3px'} to="/register" color="#228be6" component={Link}>
              Sign up
            </Text>
          </div>
        </Text>
      </form>
    </Paper>
  );
}
