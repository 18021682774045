import { Divider, Group, Title } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { BANK_DETAILS, CheckIfSims, DISTRIBUTION_NOTICE } from '../../../utils/helper';
import { IProjectTableConfig } from '../../project/types';
import LocalStorage from '../../../helpers/local-storage';
import { useMemo } from 'react';
import { renderSimsContent } from '../../../component/common/static-content/sims';

interface IFormProjectTableProps {
  tableName: string;
  description: string;
  children: React.ReactNode;
  isSubTable: boolean;
  tableConfig?: IProjectTableConfig;
}
const FormProjectTable = (props: IFormProjectTableProps) => {
  const { description, isSubTable, tableConfig, tableName } = props;

  const smallScreen = useMediaQuery('(max-width: 800px)');
  const isSims = CheckIfSims();
  const isAdmin = LocalStorage.IsAdmin();

  const projectTableVisible = useMemo(() => {
    if (!DISTRIBUTION_NOTICE.includes(tableName)) {
      return true;
    }

    const visibleToAll = tableConfig?.visibleToAll;
    if (visibleToAll) {
      return true;
    }

    if (isAdmin && !visibleToAll) {
      return true;
    }

    return false;
  }, [tableConfig, isAdmin, tableName]);

  if (!projectTableVisible) {
    return null;
  }

  return (
    <>
      {isSims && renderSimsContent(tableName)}
      <Group
        className={`${tableName}`}
        direction="column"
        position={'left'}
        sx={{
          margin: 'auto',
          maxWidth: smallScreen ? '20rem' : '60rem',
          marginBottom: '1rem',
        }}
      >
        {description !== BANK_DETAILS && (
          <div>
            <div>
              <Title align="left" mt={'lg'} order={isSubTable ? 4 : 3}>
                {description}
              </Title>
              {description && <Divider sx={{ color: 'gray', width: '20rem' }} />}
            </div>
          </div>
        )}
      </Group>
      <Group className={`props-children ${tableName}`} direction="column" align={'center'}>
        {props.children}
      </Group>
    </>
  );
};

export default FormProjectTable;
