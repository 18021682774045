import { Button, Group } from '@mantine/core';

import FormProjectTable from '../../../../pages/form-field/component/project-table';
import FormProjectTableColumn from '../../../../pages/form-field/component/project-table-columsn';
import TableRepeater from '../../../../pages/form-field/component/table-repeater';
import { IProjectTableWithChild, IProjectTableWithColumns } from '../../../../pages/project/types';
import { useFormEditHook } from '../../../../pages/submission/hooks';
import { IInsolvencyUserSubmission } from '../../../../pages/submission/types';

interface MainProps {
  data: IProjectTableWithChild;
  editData: IInsolvencyUserSubmission;
  onSaveEdit: (values: any) => void;
  loadingSubmission?: boolean;
}

export default function EditForm({ data, editData, onSaveEdit, loadingSubmission = false }: MainProps) {
  const form = useFormEditHook(data, editData);

  return (
    <>
      <form onSubmit={form.onSubmit(onSaveEdit)}>
        {data.child?.map((table: IProjectTableWithColumns, i: number) => {
          const { tableConfig } = table;

          return (
            <FormProjectTable
              isSubTable={tableConfig?.isSubTable ?? false}
              tableName={table.tableName}
              description={table.description}
              key={table.projectTableId}
              tableConfig={tableConfig}
            >
              {!tableConfig?.isTableDisplay ? (
                <FormProjectTableColumn
                  tableConfig={tableConfig}
                  form={form}
                  tableName={table.tableName}
                  columns={table.columns}
                />
              ) : (
                <TableRepeater
                  tableConfig={tableConfig}
                  key={`${table.projectTableId}`}
                  form={form}
                  table={table}
                  tableName={table.tableName}
                />
              )}
            </FormProjectTable>
          );
        })}

        <hr style={{ marginTop: '10rem' }} />
        <Group position="center" direction="column" mb={'xl'}>
          <Button type="submit" color={'dark'} size="lg" m={'lg'} loading={loadingSubmission}>
            Save
          </Button>
        </Group>
      </form>
    </>
  );
}
