import { UserTypes } from './types';

const TABLE_HEADINGS: string[] = ['Name', 'Phone', 'Email', 'Enabled', 'Comfirmed Email'];

const USER_TYPES: UserTypes = {
  CUSTOMER_USER: 'CUSTOMER_USER',
  SUPER_USER: 'SUPER_USER',
};

export { TABLE_HEADINGS, USER_TYPES };
