import { Button, Card, Group, Table } from '@mantine/core';
import { useLocation } from 'react-router-dom';
import { UseFormReturnType } from '@mantine/form';
import { randomId, useMediaQuery } from '@mantine/hooks';
import { useMemo } from 'react';
import FormField from '../../../../component/common/form-field-type/form-field-type';
import { IProjectTableConfig, IProjectTableWithColumns } from '../../../project/types';
import RowList from './row-list';
import LocalStorage from '../../../../helpers/local-storage';
import { CheckIfSims } from '../../../../utils/helper';

interface TableRepeaterProps {
  form: UseFormReturnType<any>;
  table: IProjectTableWithColumns;
  tableConfig?: IProjectTableConfig;
  tableName: string;
}

export default function TableRepeater({ form, table, tableName, tableConfig }: TableRepeaterProps) {
  const { key, ...emptyRowField } =
    useMemo(() => form.values && form.values[tableName][0], [form.values, tableName]) ?? {};

  const smallScreen = useMediaQuery('(max-width: 800px)');
  const isAdmin = LocalStorage.IsAdmin();
  const location = useLocation();
  const isNewForm = location.pathname === '/form';
  const isSims = CheckIfSims();

  Object.keys(emptyRowField).forEach(key => {
    emptyRowField[key] = '';
  });

  const newRowTable = useMemo(() => {
    return table.columns.filter(column => {
      if (!column.columnConfig) {
        return true;
      } else {
        if (column.columnConfig.isTableColumnDisplay) {
          return true;
        } else {
          return false;
        }
      }
    });
  }, [table]);

  const disableFormConditions = useMemo(() => {
    if (isSims) {
      return !isAdmin && !isNewForm && tableConfig;
    }
    return false;
  }, [isAdmin, isNewForm, tableConfig, isSims]);

  const conditionalProps = disableFormConditions ? { nonEditableFilledForm: tableConfig?.nonEditableFilledForm } : {};

  return (
    <Card
      withBorder
      shadow={'sm'}
      sx={{
        backgroundColor: '#f1f1f1',
        borderColor: '#dedede',
        minWidth: smallScreen ? '' : '60rem',
        maxWidth: smallScreen ? '20rem' : '',
      }}
      px="xl"
    >
      <div style={{ overflowX: 'auto' }}>
        {table.columns.map(column => {
          const ifNotColumnTableDisplay = newRowTable.some(newColumn => newColumn.columnName === column.columnName);

          if (!ifNotColumnTableDisplay && column.columnConfig.isTableColumnDisplay) {
            return (
              <div style={{ marginRight: '70rem' }}>
                <FormField
                  {...conditionalProps}
                  key={`${tableName}-${column.columnName}`}
                  {...column}
                  form={form}
                  tableName={tableName}
                  index={0}
                />
              </div>
            );
          }

          return null;
        })}

        <Table verticalSpacing={'xs'} highlightOnHover>
          <thead>
            <tr>
              {newRowTable.map(column => {
                return (
                  <th key={column.columnName} style={{ textAlign: 'center' }}>
                    {column.description}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            <RowList tableConfig={tableConfig} form={form} table={table} tableName={tableName} />
          </tbody>
        </Table>
      </div>

      <Group position="center" pt={'md'}>
        <Button
          type="button"
          color="gray"
          size="xs"
          disabled={Boolean(disableFormConditions)}
          onClick={() => {
            form.insertListItem(tableName, {
              ...emptyRowField,
              key: randomId(),
            });
          }}
        >
          + Add Row
        </Button>
      </Group>
    </Card>
  );
}
