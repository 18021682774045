import axios, { AxiosInstance } from 'axios';
import CatchError from '../error';
import { ICoreServiceProps, ICoreServiceResponse } from '../types';

class CoreService {
  private readonly config: ICoreServiceProps;
  public axios: AxiosInstance;
  public path: string;
  constructor(path: string, config: ICoreServiceProps) {
    this.config = config;
    this.path = path;

    this.axios = axios.create({
      baseURL: this.config.baseUrl,
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        Accept: 'application/json',
      },
      responseType: 'json',
    });
  }

  GetBaseUrl() {
    return this.config.baseUrl;
  }

  async Get(): Promise<Partial<ICoreServiceResponse>> {
    try {
      const result: ICoreServiceResponse = await this.axios.get(this.path);
      return result.data;
    } catch (e: any) {
      throw CatchError.Negotiate(e);
    }
  }
  async Post(params: any): Promise<ICoreServiceResponse> {
    try {
      const result: ICoreServiceResponse = await this.axios.post(this.path, params);
      return result.data;
    } catch (e: any) {
      throw CatchError.Negotiate(e);
    }
  }

  //@todo implement REST crud consumption
}

export default CoreService;
