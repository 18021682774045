import Alert from '../../component/common/alert';
import Content from '../../component/common/content';
import LoadingOverlay from '../../component/common/loading-overlay';
import { Footer } from '../../component/Footer';
import { CheckIfSims } from '../../utils/helper';

import MainForm from './component/form';
import Header from './component/header';
import { useGetSubmissionForm } from './hooks';
import './style.css';
export default function Form() {
  const { isLoading, error, data } = useGetSubmissionForm();

  if (error) {
    return <Alert message={error.message} />;
  }

  const isSims = CheckIfSims()
  
  return (
    <div
      style={{
        width: '100%',
        margin: 0,
        display: 'flex',
        flexDirection: 'column',
        ...(!isSims && { minWidth: 'max-content' })
      }}
    >
      {!isLoading && <LoadingOverlay visible={isLoading} />}
      <Header />
      {
        isSims && <Content />
      }
      {data && <MainForm data={data} />}
      <Footer />
    </div>
  );
}
