import { Button, Container } from '@mantine/core';
import { useGetRgmDoc } from '../../../../hooks/rgm.hook';
import { useParams } from 'react-router-dom';

export const EstimatedDistributionNotice = () => {
  const content = `
      <style>
         #hr-en {
            box-sizing: content-box;
            height: 0;
            overflow: visible;
         }
         #estimated-notice h3 {
            text-transform: uppercase;
            font-family: "Nunito", sans-serif !important;
         }

         .bold-text {
            font-weight: 700;
         }

         #edn-content ol li {
             margin: 1em;
         }

         .alpha-ordered-list {
            list-style-type:none;
         }

         .alpha-ordered-list li {
           
            counter-increment:section;
         }

         .alpha-ordered-list li:before {
            content:"(" counter(section, upper-alpha) ") ";
         }

         .edn-schedule-a {
            text-align: center;
         }

         .content-link-container{
            margin: 20px 0;
         }

         
         
      </style>

      <hr id="hr-en"/>
      <article id="edn-content" class="standard">
         <h3 >ASSESSMENT NOTICE – SETTLEMENT DISTRIBUTION SCHEME </h3>

         <div class="content-wrapper">
            <div class="content">
               <table id="table">
                  <tr>
                     <td>
                        Proceedings name:
                     </td>
                     <td>
                        Peter Hermann Eckardt v Sims Limited ACN 114 838 630 Sims Class Action - Administration of Settlement  
                     </td>
                  </tr>
                  <tr>
                     <td>
                        Proceedings number:
                     </td>
                     <td>
                        NSD220/2019
                     </td>
                  </tr>
                  <tr>
                     <td>
                        Court:
                     </td>
                     <td>
                        Federal Court of Australia
                     </td>
                  </tr>
                  <tr>
                     <td>
                        Lawyers:
                     </td>
                     <td>
                        William Roberts Lawyers (<span class="bold-text">William Roberts</span>)
                     </td>
                  </tr>
                  <tr>
                     <td>
                        Litigation Funder(s):
                     </td>
                     <td>
                        Investor Claim Partner Pty Ltd, ICP Capital Pty Limited (<span class="bold-text">ICP</span>) 
                     </td>
                  </tr>
               </table>
            </div>
            <!-- 
            <div class="content">
               <ol>
                  <li>
                     <h3>THE SETTLEMENT AND PURPOSE OF THIS CORRESPONDENCE</h3>
                     <ol>
                        <li>We refer to the previous Preliminary Notice and correspondence regarding the settlement of the Sims Class Action. </li>
                        <li>This correspondence comprises the next step required for the administration of the settlement in accordance with the court-approved Settlement Distribution Scheme (<span class="bold-text">“Settlement Distribution Scheme”</span> or <span class="bold-text">“SDS”</span>).  A copy of the SDS can be provided to you upon request. </li>
                     </ol>
                  </li>
                  <li>
                     <h3>ASSESSMENT NOTICE</h3>
                     <ol start="3">
                        <li>
                           <p>This letter, and its attached schedules, constitute the Assessment Notice for the purpose of section 4 of the SDS:</p>
                           <ol class="alpha-ordered-list">
                              <li>Schedule A to this letter sets out the expected distribution you will receive from the settlement under the SDS;</li>
                              <li>Schedule B to this letter sets out the trading data we hold in relation to your claim used to determine your estimated distribution under the SDS (<span class="bold-text">Trade Data</span>); and </li>
                              <li>Schedule C to this letter sets out additional data used to calculate the expected distribution you will receive from the settlement under the SDS.</li>
                           </ol>
                        </li>
                        <li>
                           <p>The Assessment Notice is derived using the:</p>
                           <ol class="alpha-ordered-list">
                              <li>Trade Data information that was determined following the issue of the Preliminary Notice under the SDS; and </li>
                              <li>Loss Assessment Formula contained in the Schedule to the SDS. </li>
                           </ol>
                        </li>
                        <li>
                           <p>The reason that the Assessment Notice remains an estimate is because the costs of administration of the SDS, interest accrued and any taxation implications are not known and confirmed until the administration is complete. </p>
                        </li>
                     </ol>
                  </li>
                  <li>
                     <h3>TAX IMPLICATIONS</h3>
                     <ol start="6">
                        <li>
                           <p>There will be interest income earned on the Settlement Fund during the period since settlement and any final distribution under the SDS. This may give rise to taxation implications for you.</p>
                        </li>
                        <li>
                           <p>We will withhold the amount required by the Australian Tax Office (<span class="bold-text">ATO</span>) on this taxable income.</p>
                        </li>
                        <li>
                           <p>Generally, and subject to further professional tax advice obtained by us, if you are:  </p>
                           <ol class="alpha-ordered-list">
                              <li>an Australian resident and have provided us with your Tax File Number (<span class="bold-text">TFN</span>), we will release the full amount of the interest income to which you become entitled. </li>
                              <li>not an Australian resident or have not provided us with your TFN, an amount from your assessed distribution will be withheld for submission to the Australian Tax Office. If applicable to you, the amount of the withholding is set out in Schedule C. </li>
                           </ol>
                        </li>
                        <li>
                           You should seek the advice of your taxation, financial or accounting advisers regarding the withheld amounts and/or the impact of your tax affairs.
                        </li>
                     </ol>
                  </li>
                  <li>
                     <h3>ACCURACY OF ASSESSMENT NOTICE AND ACCEPTANCE</h3>
                     <ol start="10">
                        <li>
                           Pursuant to Clauses 4.3 and 4.4 of the SDS:
                           <ol class="alpha-ordered-list">
                              <li>
                                 If you notify The Advisory Company (TAC) of any error, slip or omission in this Assessment Notice; The Administrator may correct the Assessment Notice prior to any distribution of monies in accordance with clause 8 of the SDS; 
                              </li>
                              <li>
                                 The accuracy of the Assessment Notice will be deemed accepted by you, and shall be deemed to be the Final Assessment Notice for the SDS (subject to adjustment for the matters set out at paragraph 5 above) unless, within 14 days of this Assessment Notice being given, you deliver to TAC (in accordance with the below notification details) a written request for a review under the SDS together with all documents on which you rely for the purpose of the review, including any statement of reasons for seeking such review. 
                              </li>
                           </ol>
                        </li>
                     </ol>
                     <h3>REVIEW PROCEDURE</h3>
                     <ol start="11">
                        <li>
                           <p>The review procedure for an Assessment Notice is set out at clause 5 of the SDS. In summary, it provides: </p>
                           <ol class="alpha-ordered-list">
                              <li>
                                 If you request a review of the Assessment Notice and provide the required information to TAC in accordance with paragraph 10(b), the Administrator may direct you, in writing, to submit further documentation that it considers appropriate within 14 days of the date of such written direction.  If you fail to provide such further documentation within the time required, the request for review shall be deemed never to have been made and the accuracy of the Assessment Notice issued shall be deemed to be accepted by you. 
                              </li>
                              <li>
                                 TAC will within 14 days of the later of the receipt of the request for review or the date any further documentation that is requested is received, calculate your claim and notify you of the review determination. 
                              </li>
                              <li>
                                 The review determination is final and binding and shall be the final assessment under the Scheme, unless, prior to the expiry of 14 days after notice is given to you of the review determination, you make an application (at your cost) to the Federal Court of Australia for it to review the Administrator’s review determination. Any such application is required to be served on the Administrator. 
                              </li>
                           </ol>
                        </li>
                     </ol>
                     <h3>NOTIFICATIONS AND TIMING</h3>
                     <ol start="12">
                        <li>
                           <p>Unless specified otherwise, any notification from you to us for the purposes of this Assessment Notice, is to be provided to TAC as follows:
</p>
                           <table>
                              <tbody>
                                 <tr>
                                    <td>By email</td>
                                    <td><a href="mailto:sims@theadvisoryco.com">sims@theadvisoryco.com</a></td>
                                 </tr>
                                 <tr>
                                    <td>By post</td>
                                    <td>
                                       <ul style="padding-left: 0;">
                                          <li style="margin-left: 0;">PO Box 1128</li>
                                          <li style="margin-left: 0;">Mitcham North VIC 3132 </li>
                                          <li style="margin-left: 0;">Australia</li>
                                       </ul>
                                    </td>
                                 </tr>
                                 <tr>
                                    <td>By telephone</td>
                                    <td>(03) 9872 9330</td>
                                 </tr>
                              </tbody>
                           </table>
                        </li>
                        <li>
                           <p>
                              Queries about this Assessment Notice and providing the information requested in this Assessment Notice, should be directed to TAC in the first instance using the details above. 
                           </p>
                        </li>
                        <li>
                           <p>
                              For queries or questions relating to the SDS, you can contact the Administrator (William Roberts) as follows:
                           </p>
                           <ul>
                              <li>Attn: Bill Petrovski</li>
                              <li>Sims Class Action</li>
                              <li>William Roberts Lawyers</li>
                              <li>By Email: <a href="mailto:WR-Sims@williamroberts.com.au">WR-Sims@williamroberts.com.au</a>; or </li>
                              <li>By telephone: (02) 9552 2111 </li>
                           </ul>
                        </li>
                        <li>
                           <p>
                              Please note that pursuant to clause 11 of the SDS, any notice or document to be given (or delivered) pursuant to the SDS shall be deemed to be given (or delivered) and received for all purposes associated with the SDS if it is addressed to the person to whom it is to be given; and either:
                           </p>
                           <ol class="alpha-ordered-list">
                              <li>
                                 delivered, or sent by pre-paid mail, to that person’s postal address (being, in respect of any Registered Group Member, the most resent postal address recorded in the Claimant Database);
                              </li>
                              <li>
                                 sent by fax to that person’s fax number (being, in respect of any Registered Group Member, the most recent fax number recorded in the Claimant Database) and the machine from which it is sent produces a report that states that it was sent in full; or 
                              </li>
                              <li>
                                 sent by email to that person’s email address (being, in respect of any Registered Group Member, the most resent email address recorded in the Claimant Database) and a server through which it is transmitted produces a report that states that the email has been sent to the inbox of the specified email address. 
                              </li>
                           </ol>
                           
                        </li>
                        <li>
                          <p> A notice or document that complies with paragraph 15 will be deemed to have been given (or delivered) and received:</p>
                           <ol class="alpha-ordered-list">
                              <li>
                                 if it was sent by mail to an addressee in Australia, two (2) clear business days after being sent; 
                              </li>
                              <li>
                                 if it is sent to an addressee oversees, five (5) clear business days after being sent; 
                              </li>
                              <li>
                                 if it is delivered or sent by fax, at the time stated on the report that is produced by the machine from which it is sent; or 
                              </li>
                              <li>
                                 if it is sent by email, at the time it is sent. 
                              </li>
                           </ol>
                        </li>
                        <li>
                          <p>
                            Where a Registered Group Member is not a natural person and where one person has been nominated as the contact in respect of several Registered Group Members, it is sufficient for the purpose of giving notice that any of the matters in paragraph 15 are complied with in relation to that nominated person. 
                          </p>
                        </li>
                     </ol>
                  </li>
                  <li>
                     <h3>SCHEDULE A - ESTIMATED DISTRIBUTION UNDER SDS </h3>
                  </li>
                  <li>
                     <h3>SCHEDULE B - TRADE DATA </h3>
                  </li>
                  <li>
                     <h3>SCHEDULE C - ADDITIONAL DATA USED IN ESTIMATED DISTRIBUTION</h3>
                  </li>
               </ol>
            </div>
            -->
         </div>
      </article>
     
   `;

  const params = useParams();

  const { fileName, previewUrl, isError, isSuccess } = useGetRgmDoc(params.id || '');

  return (
    <>
      <Container id="estimated-notice" sx={{ maxWidth: 'unset', marginTop: '5rem' }}>
        <div style={{ width: '100%' }} dangerouslySetInnerHTML={{ __html: content }}></div>
        {isError && <div>Cannot generate RGM Doc Link</div>}

        {isSuccess && (
          <div
            style={{
              marginTop: '2rem',
            }}
          >
            <p>
              <span style={{ fontWeight: 700 }}>File Name: </span>
              {fileName}
            </p>
            <Button component="a" href={previewUrl} rel="noreferrer" target="_blank" style={{ width: 'auto' }}>
              Click here to view the RGM's Draft Assessment Notice.
            </Button>
          </div>
        )}
      </Container>
    </>
  );
};
