import { faEnvelope, faLock, faSignIn } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, LoadingOverlay, Paper, Stack, Text, TextInput, Title } from '@mantine/core';

import { UseFormReturnType } from '@mantine/form';
import { useMediaQuery } from '@mantine/hooks';
import { useEffect } from 'react';
import { UseMutationResult } from 'react-query';
import { Link, useNavigate } from 'react-router-dom';
import Alert from '../../../component/common/alert';
import { NAV } from '../../../config/navigation';
import { IErrorObject } from '../../../services/types';
import { formPaperHeadingStyle, formPaperStyle, formPaperStyleLarge } from '../../login/styles';
import { IRegisterFormProps } from '../types';
import { CheckIfSims } from '../../../utils/helper';
interface FormProps extends IRegisterFormProps {
  confirmPassword: string;
}

interface RegisterFormProps {
  hookForm: UseFormReturnType<FormProps>;
  onRegister: UseMutationResult<void, IErrorObject, IRegisterFormProps, void>;
}

export default function RegisterForm({ hookForm, onRegister }: RegisterFormProps) {
  const navigate = useNavigate();
  const form = hookForm;
  const { mutate, isError, error, isLoading, isSuccess } = onRegister;
  const largeScreen = useMediaQuery('(min-width: 800px)');
  const handleSubmit = (value: IRegisterFormProps) => {
    const email = value.email.toLowerCase();
    mutate({ ...value, email });
  };
  const isSims = CheckIfSims();

  useEffect(() => {
    if (isSuccess) {
      navigate('verify');
    }
  }, [isSuccess, navigate]);

  return (
    <Paper withBorder shadow={'md'} sx={largeScreen ? formPaperStyleLarge : formPaperStyle}>
      <Title align="center" order={2} styles={formPaperHeadingStyle}>
        {isSims ? 'First-time sign-in' : 'Sign Up'}
      </Title>

      {isError && <Alert mt="1rem" message={error?.error?.message} />}

      <form onSubmit={form.onSubmit(({ confirmPassword, ...values }) => handleSubmit(values))}>
        <Stack spacing={10} mt="3rem">
          <LoadingOverlay visible={isLoading} />

          <TextInput
            type="email"
            {...form.getInputProps('email')}
            label="Email"
            placeholder="Email Address"
            icon={<FontAwesomeIcon icon={faEnvelope} />}
          />
          <TextInput
            type="password"
            {...form.getInputProps('password')}
            label="Password"
            placeholder="Password"
            icon={<FontAwesomeIcon icon={faLock} />}
          />
          <TextInput
            type="password"
            {...form.getInputProps('confirmPassword')}
            label="Confirm Password"
            placeholder="Confirm Password"
            icon={<FontAwesomeIcon icon={faLock} />}
          />

          <Button type="submit" px={50} mt="lg" loading={isLoading}>
            Authenticate
          </Button>
        </Stack>
        <Text size="sm" mt={'lg'}>
          <FontAwesomeIcon icon={faSignIn} style={{ paddingRight: '0.5rem' }} />
          Already signed up?
          <div>
            <Text<typeof Link> component={Link} to={NAV.LOGIN} underline size="sm" color="#228be6" pl={'3px'}>
              Click here to log in
            </Text>
          </div>
        </Text>
      </form>
    </Paper>
  );
}
