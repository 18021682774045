import { Container, Group } from '@mantine/core';
//common imports
import Alert from '../../../component/common/alert';
import Loader from '../../../component/common/loader';
import { default as LocalStorage } from '../../../helpers/local-storage';
//local imports
import { useGetProjectDetails, useLoginForm, useProcessLogin } from '../hooks';
import { loginContainerStyle } from '../styles';
import LoginBranding from './components/branding';
import { LoginForm } from './components/form';
import { useMediaQuery } from '@mantine/hooks';
import { useGetPageSetPassword, useGetPageSetPasswordFooter } from '../../project/pages/hooks';
import { ContainerMainStyles, FlexDirection, GroupContent, DivContent } from '../../auth-styles';
import { registerContainerStyle } from '../../register/styles';

const Login = () => {
  const { isLoading, error, data } = useGetProjectDetails();
  const formHook = useLoginForm();
  const processLoginHook = useProcessLogin();
  const largeScreen = useMediaQuery('(min-width: 800px)');
  const sitePageData = useGetPageSetPassword();
  const footerData = useGetPageSetPasswordFooter();

  /**
   * store customerId on local storage
   * and attach to suceeding calls
   *
   * todo: for optimization & open for other imlementations
   */
  if (data && data.customerId) {
    //save project details to local storage
    LocalStorage.SaveCustomerID(data.customerId);
    LocalStorage.SaveProjectId(data.projectId);
  }
  if (isLoading || sitePageData.isLoading || footerData.isLoading) {
    return <Loader />;
  }

  if (error) {
    return <Alert message="An error occurred while loading the page..." color="red" />;
  }
  return (
    <Container fluid={data && !sitePageData?.data?.content} sx={data && !sitePageData?.data?.content ? registerContainerStyle : {}} style={ data && !sitePageData?.data?.content ? {} : ContainerMainStyles}>
      <Group
        direction={FlexDirection(sitePageData?.data)}
        align={'center'}
        style={GroupContent}
        position="center"
        spacing={largeScreen ? 50 : 0}
      >
        {
          sitePageData?.data?.content && <div id = "divContent" style={DivContent} dangerouslySetInnerHTML={{ __html: sitePageData?.data.content }} />
        }
        {
          (data && !sitePageData?.data?.content) && <LoginBranding project={data}/>
        }
        <LoginForm formHook={formHook} loginHook={processLoginHook} />
       </Group>
       {footerData?.data?.content && <div dangerouslySetInnerHTML={{ __html: footerData?.data?.content ?? '' }} />}
    </Container>
  );
};

export default Login;
