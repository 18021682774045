import * as Yup from 'yup';
import { IProjectTableWithChild } from '../project/types';

export const useYupSchema = (data?: IProjectTableWithChild) => {
  let sortedSchema = {};
  let shape;
  if (data) {
    sortedSchema = data.child.reduce((acc, child) => {
      let tableName = child.tableName;
      if (child.tableConfig?.isTableDisplay) {
        shape = child.columns.reduce((childAcc, column) => {
          let fieldName = column.columnName;
          if (column.required) {
            return {
              ...childAcc,
              [fieldName]: Yup.string().required(
                `Please input ${column.description}`
              ),
            };
          }
          return childAcc;
        }, {});
        return {
          ...acc,
          [tableName]: Yup.array().of(Yup.object().shape(shape)),
        };
      } else {
        shape = child.columns.reduce((childAcc, column) => {
          let fieldName = column.columnName;

          if (column.required) {
            return {
              ...childAcc,
              [fieldName]: Yup.string().required(
                `Please input ${column.description}`
              ),
            };
          }
          return childAcc;
        }, {});
      }
      return { ...acc, [tableName]: Yup.object().shape(shape) };
    }, {});
  }
  return sortedSchema;
};

//
