import { Container, Group } from '@mantine/core';

import { Outlet, useLocation } from 'react-router-dom';
import Alert from '../../component/common/alert';
import Loader from '../../component/common/loader';
import LoginBranding from '../login/auth/components/branding';
import { useGetProjectDetails } from '../login/hooks';
import RegisterForm from './component/form';
import { useProcessRegistration, useRegistrationForm } from './hooks';
import { registerContainerStyle } from './styles';
import { useMediaQuery } from '@mantine/hooks';
import { useGetPageSetPassword, useGetPageSetPasswordFooter } from '../project/pages/hooks';
import { ContainerMainStyles, DivContent, FlexDirection, GroupContent } from '../auth-styles';

export default function Register() {
  const location = useLocation();
  const hookForm = useRegistrationForm();
  const hookRegister = useProcessRegistration();
  const { isLoading, error, data } = useGetProjectDetails();
  const largeScreen = useMediaQuery('(min-width: 800px)');
  const { data: passwordPageData, isLoading: setPasswordIsLoading } = useGetPageSetPassword();
  const footerData = useGetPageSetPasswordFooter();

  if (location?.pathname === '/register/verify' || location?.pathname === '/register/confirm') {
    return <Outlet />;
  }

  if (isLoading || setPasswordIsLoading || footerData.isLoading) {
    return <Loader />;
  }

  if (error) {
    return <Alert message="An error occured while loading the page..." color="red" />;
  }

  return (
    <Container
      fluid={data && !passwordPageData?.content}
      sx={data && !passwordPageData?.content ? registerContainerStyle : {}}
      style={data && !passwordPageData?.content ? {} : ContainerMainStyles}
    >
      <Group
        direction={FlexDirection(passwordPageData)}
        align={'center'}
        style={GroupContent}
        position="center"
        spacing={largeScreen ? 50 : 0}
      >
        {passwordPageData?.content && (
          <div id="divContent" style={DivContent} dangerouslySetInnerHTML={{ __html: passwordPageData.content }} />
        )}
        {data && !passwordPageData?.content && <LoginBranding project={data} />}
        <RegisterForm hookForm={hookForm} onRegister={hookRegister} />
      </Group>
      {footerData?.data?.content && <div dangerouslySetInnerHTML={{ __html: footerData?.data?.content ?? '' }} />}
    </Container>
  );
}
