import { Image } from '@mantine/core';
import { ILoginBrandingProps } from '../../types';
import { useMediaQuery } from '@mantine/hooks';

const LoginBranding = ({ project }: ILoginBrandingProps) => {
  const { projectConfig } = project;
  const { contents } = projectConfig;
  const verySmallScreen = useMediaQuery('(max-width: 670px)');
  const largeScreen = useMediaQuery('(min-width: 800px)');

  let height = 110;

  if (verySmallScreen) {
    height = 80;
  } else if (largeScreen) {
    height = 150;
  }

  return <Image height={height} fit={'contain'} src={contents.logo} withPlaceholder />;
};

export default LoginBranding;
