import { CSSObject } from '@mantine/core';

const mainGroupStyle: CSSObject = {
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
};

const formPaperStyle = {
  minWidth: '280px',
  minHeight: '550px',
  padding: '4rem',
};

const formPaperStyleLarge = {
  minWidth: '415px',
  minHeight: '550px',
  padding: '4rem',
};

const formPaperHeadingStyle = {
  marginBottom: '1rem',
  fontWeight: 'bolder',
};

const linkStyle = {
  cursor: 'pointer',
  '&:hover': { color: '#228be6' },
};

const loginContainerStyle: CSSObject = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
};

export { formPaperStyle, formPaperHeadingStyle, mainGroupStyle, linkStyle, loginContainerStyle, formPaperStyleLarge };
