import { ActionIcon, Group, Table } from '@mantine/core';
import Loader from '../../../component/common/loader';
import { ISuperUser, IUser } from '../types';
import { TABLE_HEADINGS, USER_TYPES } from '../constants';
import { Edit, Trash, Check } from 'tabler-icons-react';
import { FC } from 'react';

interface UserTableProps {
  onSelectForEdit: (id: string, action: string) => void;
  loading: boolean;
  users: any;
  userType: string;
}

export const UsersTable: FC<UserTableProps> = ({ onSelectForEdit, loading, users, userType }) => {
  if (loading) {
    return <Loader />;
  }

  const renderUsers = () => {
    if (userType === USER_TYPES['CUSTOMER_USER']) {
      return users.map((user: IUser) => (
        <tr key={user.customerUserId}>
          <td>{user.name}</td>
          <td>{user.phone}</td>
          <td>{user.email}</td>
          <td>{`${user.enabled ? 'Enabled' : 'Disabled'}`}</td>
          <td>{`${user.emailConfirmed ? user.emailConfirmed : 'Uncomfirmed'}`}</td>
          <td>
            <Group>
              
              <ActionIcon
                size="lg"
                variant="filled"
                color="green"
                disabled={!!user.emailConfirmed}
                title='Verify Account'
                onClick={() => onSelectForEdit(user.customerUserId, 'VERIFY')}
              >
                <Check size="1.625rem" />
              </ActionIcon>
              <ActionIcon
                size="lg"
                variant="filled"
                color="orange"
                title='Edit Account'
                onClick={() => onSelectForEdit(user.customerUserId, 'EDIT')}
              >
                <Edit size="1.625rem" />
              </ActionIcon>
              <ActionIcon
                size="lg"
                variant="filled"
                color="red"
                title='Remove Account'
                onClick={() => onSelectForEdit(user.customerUserId, 'DELETE')}
              >
                <Trash size="1.625rem" />
              </ActionIcon>
            </Group>
          </td>
        </tr>
      ));
    }

    if (userType === USER_TYPES['SUPER_USER']) {
      return users.map((user: ISuperUser) => (
        <tr key={user.systemUserId}>
          <td>{user.name}</td>
          <td>{user.phone}</td>
          <td>{user.email}</td>
          <td>{`${user.enabled ? 'Enabled' : 'Disabled'}`}</td>
          <td>{`${user.emailConfirmed ? user.emailConfirmed : 'Uncomfirmed'}`}</td>
          <td>
            <Group>
              <ActionIcon
                size="lg"
                variant="filled"
                color="orange"
                onClick={() => onSelectForEdit(user.systemUserId, 'EDIT')}
              >
                <Edit size="1.625rem" />
              </ActionIcon>
              <ActionIcon
                size="lg"
                variant="filled"
                color="red"
                onClick={() => onSelectForEdit(user.systemUserId, 'DELETE')}
              >
                <Trash size="1.625rem" />
              </ActionIcon>
            </Group>
          </td>
        </tr>
      ));
    }
  };

  return (
    <Table
      border={1}
      highlightOnHover
      verticalSpacing="xs"
      style={{ whiteSpace: 'nowrap', border: '1px solid #dfe1e2' }}
      className="users-table"
    >
      <thead>
        <tr>
          {TABLE_HEADINGS.map(heading => (
            <th key={heading}>{heading}</th>
          ))}
          <th>Action</th>
        </tr>
      </thead>
      <tbody>{renderUsers()}</tbody>
    </Table>
  );
};
