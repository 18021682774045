import { Link } from 'react-router-dom';
import { NAV } from '../../../../config/navigation';
import { luxonLocal } from '../../../../helpers/luxon.service';
import { ITableHeaderMap } from '../../types';
import View from '../../view/components/view-docs';

const SubmissionListData = ({ submissions, headers }: { submissions: any; headers: ITableHeaderMap }) => {
  const trows = submissions.map((item: any) => {
    const tableNames = Object.keys(headers);
    const createdOn = luxonLocal.DateTime.fromISO(item?.meta?.created_on || '').toLocaleString(
      luxonLocal.DateTime.DATETIME_SHORT,
    );
    const tableCols: any = [
      <td key={`${item.id}-column-action`}>
        <Link to={`${NAV.ADMIN_SUBMISSION_LIST}/${item.id}`}>View</Link>
      </td>,
      <td key={`${item.id}-created_on`}>{createdOn}</td>,
      <td key={`${item.id}-updated_on`}>{item?.meta?.updated_on}</td>,
      <td key={`${item.id}-submitted_by`}>
        <a target="_blank" rel="noreferrer" href={`mailto:${item?.meta.submitted_by.email}`}>
          {item?.meta?.submitted_by?.email}
        </a>
      </td>,
    ];

    tableNames.forEach((tableName: string) => {
      const currentTableValues = item[tableName];

      const { columns } = headers[tableName];
      if (currentTableValues) {
        const cols = Object.keys(columns).map((columnName: string, rowIcr: number) => {
          let value;

          switch (typeof currentTableValues[columnName]) {
            case 'object':
              if (Array.isArray(currentTableValues[columnName])) {
                value = currentTableValues[columnName][0];
              } else if (!currentTableValues[columnName]) {
                value = '';
              } else {
                value = <View key={`${columnName}-${rowIcr}-download`} fileId={currentTableValues[columnName]} />;
              }
              break;
            case 'boolean':
              value = currentTableValues[columnName] ? 'Accepted' : 'Not yet accepted';
              break;
            default:
              value = currentTableValues[columnName];
              break;
          }

          return <td key={`${columnName}-${rowIcr}`}>{value}</td>;
        });
        tableCols.push(cols);
      }
    });

    return <tr key={item.id}>{tableCols}</tr>;
  });
  return <tbody>{trows}</tbody>;
};

export default SubmissionListData;
