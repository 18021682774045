import CoreConfig from "../../../config/core";
import CoreService from "../../../services/core";
import CatchError from "../../../services/error";
import { ICoreServiceProps } from "../../../services/types";

class SetPasswordModel extends CoreService {
   constructor(path: string, config: ICoreServiceProps) {
      super(path, config);
      this.path = path;
   }

   async SetNewPassword({ resetCode, password }: { resetCode: string; password: string; }) {
      try {
         await this.axios.post(`${this.path}/set-password`, { resetCode, password});
      } catch (e: any) {
         throw CatchError.Negotiate(e);
      }
   }
}

export default new SetPasswordModel(`/customer-users`, CoreConfig);
