import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Select } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { useEffect, useState } from 'react';
import { FormFieldProps } from './types';

export default function InputSelect({ hideLabel = false, ...props }: FormFieldProps) {
  const { selectOptions } = props;

  const options = selectOptions?.flat().map(option => {
    /* eslint-disable */
    /**
     * the formatting below is needed by backend postgreSQL structure
     */
    return {
      value: `\{${option}\}`,
      label: option,
    };
    /* eslint-enable */
  });

  const [inputProps, setinputProps] = useState(`${props.tableName}.${props.columnName}`);

  useEffect(() => {
    if (props.index !== undefined) setinputProps(`${props.tableName}.${props.index}.${props.columnName}`);
  }, [props.columnName, props.index, props.tableName]);

  const smallScreen = useMediaQuery('(max-width: 800px)');

  const inputValueProps = { ...props.form.getInputProps(inputProps) };
  const isDirty = props.form.isDirty(inputProps);
  const hasInitialValue = !!inputValueProps?.value;

  const disableForm = props.nonEditableFilledForm && !isDirty && hasInitialValue;

  return options ? (
    <Select
      name={props.columnName}
      label={!hideLabel && props.description}
      {...props.form.getInputProps(inputProps)}
      placeholder="Select Applicable"
      disabled={disableForm}
      data={options}
      rightSection={<FontAwesomeIcon icon={faChevronDown} color="rgb(134, 142, 150)" />}
      styles={{
        root: {
          display: 'flex',
          alignItems: 'center',
          margin: '0.5rem',
          justifyContent: 'space-between',
          flexFlow: smallScreen ? 'column' : 'row',
        },
        label: {
          paddingRight: '0.5rem',
        },
        error: {
          paddingLeft: '4px',
        },
        input: {
          width: '15rem',
        },
      }}
    />
  ) : (
    <></>
  );
}
