import { Container, Group, Paper, Text, Title } from '@mantine/core';
import { useMemo } from 'react';
import { useGetProjectDetails } from '../../../project-settings/hooks';
import { useCalculateSubmissionSummary } from './hooks';

const Summary = ({ result }: { result: any }) => {
  const { data: projects, isLoading, isSuccess } = useGetProjectDetails();
  const submissionSummary = useMemo(
    () => isSuccess && projects.projectConfig?.table?.submissionSummary,
    [projects, isSuccess],
  );
  const { notYetSubmitted, SubmittedVerifiedCorrect, SubmittedVerifiedAmendments } = useCalculateSubmissionSummary(
    result,
    submissionSummary,
  );

  if (!isLoading && submissionSummary?.enabled)
    return (
      <Container p={'sm'}>
        <Title>Submissions Summary</Title>
        <Paper shadow={'sm'} withBorder p={'sm'}>
          <Group direction="column">
            <Text>Not Yet Submitted : {notYetSubmitted}</Text>
            <Text>Submitted, Verified and Correct : {SubmittedVerifiedCorrect}</Text>
            <Text>Submitted, Verified and Amendments requested : {SubmittedVerifiedAmendments}</Text>
          </Group>
        </Paper>
      </Container>
    );

  return <></>;
};

export default Summary;
