import { useForm, yupResolver } from '@mantine/form';
import { useMutation } from 'react-query';
import { IErrorObject } from '../../services/types';
import RegisterModel from './model/register.model';
import { registrationSchema, sendEmailVerificationSchema } from './schema';
import { IRegisterFormProps } from './types';

export function useRegistrationForm() {
  return useForm({
    initialValues: {
      email: '',
      password: '',
      confirmPassword: '',
    },
    validate: yupResolver(registrationSchema),
  });
}

export const useProcessRegistration = () => {
  return useMutation<void, IErrorObject, IRegisterFormProps, void>(
    async ({ email, password }: IRegisterFormProps) => {
      return await RegisterModel.Register({ email, password });
    },
    {}
  );
};

export const useProcessConfirmation = () => {
  return useMutation<any, IErrorObject, { code: string }, any>(
    async ({ code }: { code: string }) => {
      return await RegisterModel.Verify({ code });
    },
    {}
  );
};

export function useSendVerificationForm() {
  return useForm({
    initialValues: {
      email: '',
    },
    validate: yupResolver(sendEmailVerificationSchema),
  });
}

export const useProcessResendVerification = () => {
  return useMutation<any, IErrorObject, { email: string }, any>(
    async ({ email }: { email: string }) => {
      return await RegisterModel.Resend({ email });
    },
    {}
  );
};
