import { Anchor, Button, createStyles, Group, Space, Table, Title } from '@mantine/core';
import { Link } from 'react-router-dom';
import Alert from '../../../component/common/alert';
import { NAV } from '../../../config/navigation';
import { IInsolvencyUserSubmission } from '../types';
import { useGetProjectDetails } from '../../login/hooks';

const UserSubmissionTable = ({ data }: { data: IInsolvencyUserSubmission[] }) => {
  const { data: projectDetails } = useGetProjectDetails();

  const { classes } = createStyles(theme => ({
    root: {
      [theme.fn.largerThan('sm')]: {
        minWidth: '35rem',
      },
    },
  }))();

  const previewColumns = projectDetails?.projectConfig.table.submissionPreviewColumns || [];

  const isProjectSims = (projectDetails && projectDetails.projectName.toLowerCase() === 'sims') || false;
  const rows = data.map((item: IInsolvencyUserSubmission, i: number) => (
    <tr key={i}>
      {!isProjectSims && <td>{item.created_on}</td>}
      {previewColumns.map(column => (
        <td key={column.key}>{item[column.key as keyof typeof item] || '-'}</td>
      ))}
      <td>
        <Button component={Link} color="gray" to={`${NAV.USER_SUBMISSIONS_LIST}/${item.id}`}>
          View
        </Button>
      </td>
    </tr>
  ));

  return (
    <div style={{ maxWidth: '50rem' }} className={classes.root}>
      <Group grow>
        <Title order={3} align="justify">
          Eligible Submissions
        </Title>
        {!isProjectSims && (
          <Anchor align="right" underline={false} href={NAV.FORM}>
            + New Submission
          </Anchor>
        )}
      </Group>
      <Space h={20} />
      <Table highlightOnHover verticalSpacing={15}>
        <thead>
          <tr>
            {!isProjectSims && <th>Submission Date</th>}
            {previewColumns.map(column => (
              <th key={column.key}>{column.title}</th>
            ))}
            <th>Action</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>

      {data.length < 1 && (
        <>
          <Space h={20} />
          <Alert
            title="You do not have any submissions."
            color="blue"
            message={
              projectDetails?.projectName !== 'Sims'
                ? 'Click “+ New Submission” to commence. Once you’ve finished, you can use it again to register for more than one entity.'
                : ''
            }
          />
        </>
      )}
    </div>
  );
};

export default UserSubmissionTable;
