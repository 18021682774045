import { FC, useEffect } from 'react';
import { IUser, IUserPayload, UserPayload } from '../types';
import { Button, Group, Switch, TextInput } from '@mantine/core';
import { EditFormInputStyle } from './edit-form.style';
import { useForm, yupResolver } from '@mantine/form';
import { UpdateUserSchema } from '../schema';
import { useUpdateCustomer } from '../hooks';
import { showNotification } from '@mantine/notifications';
import { IErrorObject } from '../../../services/types';

interface EditFormProps {
  onClose: () => void;
  userDetails: IUser;
  userId: string;
  type: string;
  refetch: () => void;
}
export const EditForm: FC<EditFormProps> = ({ onClose, userDetails, userId, type, refetch }) => {
  const { mutate, isLoading: isProcessingUpdate, isSuccess, isError, error } = useUpdateCustomer(type);
  const form = useForm({
    validate: yupResolver(UpdateUserSchema),
    initialValues: {
      name: userDetails.name,
      phone: userDetails.phone,
      enabled: userDetails.enabled,
      email: userDetails.email,
      type: type,
    },
  });

  const handleOnSubmit = (values: UserPayload) => {
    const payload: IUserPayload = {
      id: userId,
      request: {
        name: values.name,
        phone: values.phone,
        enabled: values.enabled,
        email: values.email,
      },
    };
    mutate(payload);
  };

  useEffect(() => {
    if (isSuccess) {
      showNotification({
        message: 'User has been updated.',
        title: 'User Management',
        autoClose: 3000,
        color: 'green',
      });
      form.reset();
      refetch();
      onClose();
    }
    // addinn onClose calls showNotification 2x
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      const errorResponse: IErrorObject = error as IErrorObject;
      showNotification({
        message: errorResponse.error.message,
        title: 'User Management',
        autoClose: 3000,
        color: 'red',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  return (
    <form onSubmit={form.onSubmit(values => handleOnSubmit(values))}>
      <TextInput {...form.getInputProps('name')} label="Name" style={EditFormInputStyle} />
      <TextInput {...form.getInputProps('phone')} label="Phone" style={EditFormInputStyle} />
      <TextInput {...form.getInputProps('email')} label="Email" style={EditFormInputStyle} />
      <Switch {...form.getInputProps('enabled', { type: 'checkbox' })} label="Enable User" style={EditFormInputStyle} />
      <Group position="center">
        <Button variant="outline" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="filled" type="submit" disabled={isProcessingUpdate}>
          Update
        </Button>
      </Group>
    </form>
  );
};
