import { useMemo } from 'react';
import { UseFormReturnType } from '@mantine/form';
import { IProjectTableConfig, IProjectTableWithColumns } from '../../../project/types';
import RowField from './row-field';
interface RowFieldsProps {
  form: UseFormReturnType<any>;
  table: IProjectTableWithColumns;
  tableName: string;
  tableConfig?: IProjectTableConfig;
}

export default function RowList({ form, tableName, table, tableConfig }: RowFieldsProps) {
  const rowList = useMemo(() => {
    if (tableConfig?.sortConfig?.sortColumn && tableConfig?.sortConfig?.sortDirection) {
      return form.values[tableName]?.sort((a: any, b: any) => {
        const { sortColumn, sortDirection } = tableConfig.sortConfig!;
        if (sortDirection === 'ASC') {
          return a[sortColumn] - b[sortColumn]
        } else {
          return b[sortColumn] - a[sortColumn]
        }
      })
    }

    return form.values[tableName]
  }, [form, tableConfig, tableName])

  return rowList?.map((columnName: any, index: number) => (
    <RowField
      tableConfig={tableConfig}
      key={columnName.key}
      index={index}
      form={form}
      table={table}
      tableName={tableName}
    />
  ));
}
