import { Button, Collapse, Paper, SimpleGrid, Space, TextInput } from '@mantine/core';
import { DateRangePicker } from '@mantine/dates';
import { useForm } from '@mantine/form';
import { useState } from 'react';
import { Filter } from 'tabler-icons-react';
const FilterBox = ({ handler }: { handler: Function }) => {
  const [opened, setOpened] = useState(false);
  const form = useForm();

  const SubmitFilters = (values: any) => {
    handler(values);
  };
  return (
    <>
      <Button onClick={() => setOpened(o => !o)} variant="subtle" leftIcon={<Filter />} size="xs">
        Toggle Filters
      </Button>

      <Collapse in={opened} className="filter-toggle">
        <Paper p="md" withBorder>
          <form onSubmit={form.onSubmit(values => SubmitFilters(values))}>
            {/* <form onSubmit={form.onSubmit(SubmitFilters)}> */}
            <SimpleGrid cols={12}>
              <DateRangePicker
                name="daterange"
                label="Submission Date"
                placeholder="click to pick dates range"
                {...form.getInputProps('daterange')}
              />
              <TextInput name="search" label="Search" placeholder="Search" {...form.getInputProps('search')} />
              {/** provide other filter fields here**/}
              <div>
                <Space h={26} />
                <Button variant="filled" leftIcon={<Filter />} type="submit">
                  Apply Filters
                </Button>
              </div>
            </SimpleGrid>
          </form>
        </Paper>
      </Collapse>
    </>
  );
};

export default FilterBox;
