import { Button, Group, Modal } from '@mantine/core';
import { FC, useEffect } from 'react';
import { useVerifyCustomer } from '../hooks';
import { showNotification } from '@mantine/notifications';
import { IErrorObject } from '../../../services/types';

interface VerifyModalProps {
  userId: string;
  opened: boolean;
  onClose: () => void;
}

export const VerifyUserModal: FC<VerifyModalProps> = ({ userId, opened, onClose }) => {

  const { mutate, isLoading, isSuccess, isError, error } = useVerifyCustomer();

  const onVerify = () => {
    mutate(userId);
  }

  useEffect(() => {
    if (isSuccess) {
      showNotification({
        message: 'User has been confirmed.',
        title: 'Success',
        autoClose: 3000,
        color: 'green',
      });
      onClose();
    }
    // addinn onClose calls showNotification 2x
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      const errorResponse: IErrorObject = error as IErrorObject;
      showNotification({
        message: errorResponse.error.message,
        title: 'Failed',
        autoClose: 3000,
        color: 'red',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);
  
  return (
   <Modal closeOnClickOutside={false} title="Confirm Verify User?" opened={opened} closeOnEscape onClose={onClose}>
      <Group position="center">
        <Button variant="outline" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="filled" color="green" disabled={isLoading} onClick={() => onVerify()}>
          Verify
        </Button>
      </Group>
    </Modal>
  );
};
