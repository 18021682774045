import * as Yup from 'yup';

export const UpdateUserSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  phone: Yup.string().required('Phone is required'),
});

export const CreateUserSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  phone: Yup.string().required('Phone is required'),
  email: Yup.string().email().required('Email is required'),
  password: Yup.string().min(8, 'Password should be at least 8 characters long').required('Password is required'),
  confirmPassword: Yup.string().oneOf([Yup.ref('password')], 'Passwords do not match'),
});
