/**
 * different name to avoid conflict with
 * pagination of @mantine/core
 * try to use namespacing???
 */

import { Pagination } from '@mantine/core';
import { IPagination } from './types';

interface IZipzipPaginationProps {
  pagination: IPagination;
  changeHandler: Function;
}
const ZipZipPagination = ({ pagination, changeHandler }: IZipzipPaginationProps) => {
  const { currentPage, totalPages, totalItems } = pagination;
  return (
    <Pagination
      page={currentPage}
      total={totalPages}
      hidden={!totalItems}
      onChange={(page: number) => {
        changeHandler(page);
      }}
    />
  );
};

export default ZipZipPagination;
