import { Outlet } from "react-router-dom";
import LocalStorage from "../../helpers/local-storage";
import AccessDenied from "../common/access-denied";

// interface PrivateRouteProps {
//    children: JSX.Element;
// }

// enum Role {
//    Admin = "admin",
//    Customer = "customer",
// }

const PrivateRoute = () => {
   // const location = useLocation();
   // const role = LocalStorage.GetUserType();
   const isAuth = LocalStorage.GetIsAuthenticated();

   if (!isAuth) {
      return <AccessDenied />;
   }

   return <Outlet />;
};

export default PrivateRoute;
