import * as Yup from 'yup';

export const registrationSchema = Yup.object().shape({
  email: Yup.string().required('Please enter your email'),
  password: Yup.string().required('Password is required'),
  confirmPassword: Yup.string().oneOf(
    [Yup.ref('password'), null],
    'Password did not match'
  ),
});

export const sendEmailVerificationSchema = Yup.object().shape({
  email: Yup.string().required('Please enter your email'),
});
