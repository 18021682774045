import { Button } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { useMemo } from 'react';
import { Trash } from 'tabler-icons-react';
import FormField from '../../../../component/common/form-field-type/form-field-type';
import { IProjectTableConfig, IProjectTableWithColumns } from '../../../project/types';
import { useLocation } from 'react-router-dom';
import LocalStorage from '../../../../helpers/local-storage';

interface RowFieldProps {
  form: UseFormReturnType<any>;
  table: IProjectTableWithColumns;
  tableName: string;
  index: number;
  tableConfig?: IProjectTableConfig;
}

export default function RowField({ form, table, tableName, index, tableConfig }: RowFieldProps) {
  const newRowTable = useMemo(() => {
    return table.columns.filter(column => {
      if (!column.columnConfig) {
        return true;
      } else {
        if (column.columnConfig.isTableColumnDisplay) {
          return true;
        } else {
          return false;
        }
      }
    });
  }, [table]);

  const isAdmin = LocalStorage.GetRoleId();
  const location = useLocation();
  const isNewForm = location.pathname === '/form';
  const disableFormConditions = !isAdmin && !isNewForm && tableConfig;
  const conditionalProps = disableFormConditions ? { nonEditableFilledForm: tableConfig.nonEditableFilledForm } : {};

  return (
    <tr>
      {newRowTable &&
        newRowTable.map(column => (
          <td key={column?.columnName}>
            <FormField
              {...conditionalProps}
              {...column}
              form={form}
              tableName={tableName}
              hideLabel={true}
              index={index}
            />
          </td>
        ))}
      <td>
        <Button
          disabled={Boolean(disableFormConditions)}
          variant="outline"
          color="gray"
          size="xs"
          onClick={() => form.removeListItem(tableName, index)}
        >
          <Trash size={18} strokeWidth={2} color="gray" />
        </Button>
      </td>
    </tr>
  );
}
