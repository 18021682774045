import {useQuery} from 'react-query';
import PagesModel from './pages.model';
import {ProjectPage} from './types';

export const useGetPage = (path: string) => {
  return useQuery<ProjectPage, any>(
    ['projectPage'],
    () => {
      return PagesModel.getPage(path);
    }
  );
};

export const useGetPageSetPassword = () => {
  return useQuery<ProjectPage, any>(
    ['projectPageSetPassword'],
    () => {
      return PagesModel.getPage('set_password');
    }
  );
};

export const useGetPageSetPasswordFooter = () => {
  return useQuery<ProjectPage, any>(
    ['projectPageSetPasswordfooter'],
    () => {
      return PagesModel.getPage('set_password_footer');
    }
  );
};