import { Container, Group, ScrollArea, Title, Tabs } from '@mantine/core';
import { useMediaQuery, useDisclosure } from '@mantine/hooks';

import { UsersTable } from './components/users-table';
import { UserModal } from './components/user-modal';
import { useCallback, useMemo, useState } from 'react';
import { DeleteUserModal } from './components/delete-modal';
import { useGetCustomerUsers, useGetSuperUsers } from './hooks';
import { CreateUser } from './components/create-user';
import { USER_TYPES } from './constants';
import { VerifyUserModal } from './components/verify-modal';
import ZipZipPagination from '../../component/common/pagination';

const UserManagement = () => {
  const largeScreen = useMediaQuery('(min-width: 900px)');
  const [selectedCustomer, setSelectedCustomer] = useState<string | null>(null);
  const [verfiedCustomer, setVerifiedCustomer] = useState<null | string>(null);
  const [action, setAction] = useState<'EDIT' | 'DELETE' | 'VERIFY' | null>(null);
  const [activeTab, setActiveTab] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const { data: userData, isLoading: isLoadingUsers, refetch: refetchCustomerUsers } = useGetCustomerUsers(currentPage);
  const { data: superUsersData, isLoading: isLoadingSuperUsers, refetch: refetchSuperUsers } = useGetSuperUsers();
  const customerUsers = userData ? userData?.data?.users : [];
  const superUsers = superUsersData ? superUsersData.users : [];

  const [editOpened, { open: openEditModal, close: closeEditModal }] = useDisclosure(false);
  const [deleteOpened, { open: openDeleteModal, close: closeDeleteModal }] = useDisclosure(false);
  const [verifyOpened, { open: openVerifyeModal, close: closeVerifyeModal }] = useDisclosure(false);

  const handleRefetch = useCallback(() => {
    if (activeTab === 0) {
      refetchCustomerUsers();
    } else {
      refetchSuperUsers();
    }
  }, [activeTab, refetchCustomerUsers, refetchSuperUsers]);

  const selectCustomerForEdit = (id: string, action: string) => {
    if (action === 'VERIFY') {
      setAction('VERIFY');
      setVerifiedCustomer(id);
      openVerifyeModal()
      return;
    }
    setSelectedCustomer(id);
    if (action === 'EDIT') {
      setAction('EDIT');
      openEditModal();
    } else {
      setAction('DELETE');
      openDeleteModal();
    }
  };

  const onCloseEditModal = useCallback(() => {
    setSelectedCustomer(null);
    closeEditModal();
    handleRefetch();
  }, [closeEditModal, handleRefetch]);

  const onCloseDeleteModal = useCallback(() => {
    setSelectedCustomer(null);
    closeDeleteModal();
    handleRefetch();
  }, [closeDeleteModal, handleRefetch]);

  const onCloseVerifyModal = useCallback(() => {
    setVerifiedCustomer(null);
    setAction(null);
    closeVerifyeModal();
    handleRefetch()
  }, [closeVerifyeModal, handleRefetch])

  const activeUserType = useMemo(() => {
    if (activeTab === 0) {
      return USER_TYPES['CUSTOMER_USER'];
    }
    return USER_TYPES['SUPER_USER'];
  }, [activeTab]);

  return (
    <Container fluid px={0}>
      <ScrollArea py={'lg'} px={largeScreen ? 'lg' : 0}>
        <Group sx={{ justifyContent: 'space-between', margin: '1rem 0' }}>
          <Title order={3} pb={'0.5rem'} align="justify">
            User Management
          </Title>
          <CreateUser refetch={handleRefetch} userType={activeUserType} />
        </Group>
        <Tabs active={activeTab} onTabChange={setActiveTab}>
          <Tabs.Tab label="Customer Users" value={USER_TYPES['CUSTOMER_USER']}>
            <UsersTable
              userType={USER_TYPES['CUSTOMER_USER']}
              loading={isLoadingUsers}
              users={customerUsers}
              onSelectForEdit={selectCustomerForEdit}
            />
            <div style={{marginTop: '2rem'}}>
              {userData?.meta?.pagination && (
                <ZipZipPagination pagination={userData?.meta?.pagination} changeHandler={setCurrentPage} />
              )}
            </div>
           
          </Tabs.Tab>
          <Tabs.Tab label="Super Users" value={USER_TYPES['CUSTOMER_USER']}>
            <UsersTable
              userType={USER_TYPES['SUPER_USER']}
              loading={isLoadingSuperUsers}
              users={superUsers}
              onSelectForEdit={selectCustomerForEdit}
            />
          </Tabs.Tab>
        </Tabs>

        {action === 'EDIT' && selectedCustomer && (
          <UserModal type={activeUserType} userId={selectedCustomer} opened={editOpened} onClose={onCloseEditModal} />
        )}
        {action === 'DELETE' && selectedCustomer && (
          <DeleteUserModal
            userType={activeUserType}
            onClose={onCloseDeleteModal}
            opened={deleteOpened}
            userId={selectedCustomer}
          />
        )}

        {
          action === 'VERIFY' && verfiedCustomer && (
            <VerifyUserModal
              onClose={onCloseVerifyModal}
              opened={verifyOpened}
              userId={verfiedCustomer}
            />
          )
        }
      </ScrollArea>
    </Container>
  );
};

export default UserManagement;
