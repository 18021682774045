import { Button, Group, Modal } from '@mantine/core';
import { FC, useEffect } from 'react';
import { useDeleteCustomer } from '../hooks';
import { showNotification } from '@mantine/notifications';
import { IErrorObject } from '../../../services/types';

interface UserModalProps {
  userId: string;
  opened: boolean;
  onClose: () => void;
  userType: string;
}

export const DeleteUserModal: FC<UserModalProps> = ({ userId, opened, onClose, userType }) => {
  const { mutate, isSuccess, isError, error } = useDeleteCustomer(userType);

  const onRemove = () => {
    mutate(userId);
  };

  useEffect(() => {
    if (isSuccess) {
      showNotification({
        message: 'User has benn removed.',
        title: 'Success',
        autoClose: 3000,
        color: 'green',
      });
      onClose();
    }

    // adding onClose calls showNotification 2x
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      const errorResponse: IErrorObject = error as IErrorObject;
      showNotification({
        message: errorResponse.error.message,
        title: 'Failed',
        autoClose: 3000,
        color: 'red',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  return (
    <Modal closeOnClickOutside={false} title="Comfirm Remove User?" opened={opened} closeOnEscape onClose={onClose}>
      <Group position="center">
        <Button variant="outline" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="filled" color="red" onClick={() => onRemove()}>
          Remove
        </Button>
      </Group>
    </Modal>
  );
};
