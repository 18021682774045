import CoreConfig from '../../../../config/core';
import localStorage from '../../../../helpers/local-storage';
// import localStorage from "../../../../helpers/local-storage";
import CoreService from '../../../../services/core';
import CatchError from '../../../../services/error';
import { ICoreServiceProps, ICoreServiceResponse } from '../../../../services/types';
import { IUserAdminProfile, IUserProfile } from '../../../user/types';
// const customerUserId = localStorage.GetCustomerID();

class AuthModel extends CoreService {
  constructor(path: string, config: ICoreServiceProps) {
    super(path, config);
    this.path = path;
  }

  async Authenticate(email: string, password: string): Promise<IUserProfile | IUserAdminProfile> {
    try {
      const result: ICoreServiceResponse = await this.axios.post(`${this.path}/login`, { email, password });
      return result.data.data;
    } catch (e: any) {
      throw CatchError.Negotiate(e);
    }
  }
  async Logout() {
    try {
      await this.axios.post(`${this.path}/logout`);
      localStorage.ClearAll();
    } catch (e: any) {
      throw CatchError.Negotiate(e);
    }
  }
}
export default new AuthModel('/auth', CoreConfig);
