import { Modal } from '@mantine/core';
import { FC } from 'react';
import { useGetUserById } from '../hooks';
import OverlayLoader from '../../../component/common/loading-overlay';
import { EditForm } from './edit-form';

interface UserModalProps {
  userId: string;
  opened: boolean;
  type: string;
  onClose: () => void;
}

export const UserModal: FC<UserModalProps> = ({ userId, opened, onClose, type }) => {
  const { data, isLoading, refetch } = useGetUserById(userId, type);

  return (
    <Modal closeOnClickOutside={false} title="Edit User" opened={opened} closeOnEscape onClose={onClose}>
      {isLoading ? (
        <OverlayLoader visible />
      ) : (
        <EditForm type={type} userId={userId} userDetails={data.user} onClose={onClose} refetch={refetch} />
      )}
    </Modal>
  );
};
