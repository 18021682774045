import {
  Anchor,
  Avatar,
  Burger,
  Button,
  createStyles,
  Divider,
  Drawer,
  Group,
  Header as MantineHeader,
  Image,
  Paper,
  ScrollArea,
  Text,
  Title,
} from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { NAV } from '../../../config/navigation';
import localStorage from '../../../helpers/local-storage';
import { useGetProjectDetails, useProcessLogout } from '../../login/hooks';
import { alignCenter, headerStyle } from './styles';
import { useDisclosure } from '@mantine/hooks';

export default function Header() {
  const { data: userDetails } = useGetProjectDetails();
  const navigate = useNavigate();
  const { isSuccess, mutate, isError, error } = useProcessLogout();

  const handleLogout = async () => {
    mutate();
  };

  if (isSuccess) navigate(NAV.LOGIN);
  useEffect(() => {
    if (isError) {
      showNotification({
        message: error.error.message,
        autoClose: 2000,

        color: 'red',
      });
    }
  }, [error, isError]);

  const useStyles = createStyles(theme => ({
    hiddenMobile: {
      [theme.fn.smallerThan('sm')]: {
        display: 'none',
      },
    },

    hiddenDesktop: {
      [theme.fn.largerThan('sm')]: {
        display: 'none',
      },
    },

    navItems: {
      display: 'flex',
      alignItems: 'center',
    },

    projectHeading: {
      minWidth: '15rem',
      overflow: 'hidden',
      color: '#818181',
      fontSize: '1.3rem'
    },
  }));

  const [drawerOpened, { toggle: toggleDrawer, close: closeDrawer }] = useDisclosure(false);

  const { classes, cx } = useStyles();

  return (
    <>
      {userDetails && (
        <>
          <MantineHeader height={100} style={headerStyle}>
            <div style={alignCenter}>
              <Image src={userDetails.projectConfig.contents.logo} withPlaceholder fit="contain" width={200} />
              <Title order={1} pl={'lg'} className={cx(classes.projectHeading, classes.hiddenMobile)}>
                {userDetails.projectConfig.contents.heading}
              </Title>
            </div>

            <div style={{ flex: 1 }} />
            <Burger opened={drawerOpened} onClick={toggleDrawer} size="sm" className={classes.hiddenDesktop} />

            <div className={cx(classes.navItems, classes.hiddenMobile)}>
              <Anchor href={NAV.USER_SUBMISSIONS_LIST}>Dashboard</Anchor>
              <Divider
                orientation="vertical"
                size={'md'}
                variant="solid"
                sx={{ height: '2.5rem', margin: '1rem' }}
                color="dark"
              />
              <Text color="dimmed" size="xl">
                {localStorage.Get('email')}
              </Text>
              <Divider
                orientation="vertical"
                size={'md'}
                variant="solid"
                sx={{ height: '2.5rem', margin: '1rem' }}
                color="dark"
              />
              <Button
                variant="outline"
                styles={{
                  outline: { borderColor: 'gray', color: 'dimgray' },
                }}
                onClick={handleLogout}
              >
                Logout
              </Button>
              <Avatar radius="xl" ml={'xl'} size="lg" styles={{ root: { border: '1px black solid' } }} />
            </div>
          </MantineHeader>
          <Drawer
            opened={drawerOpened}
            onClose={closeDrawer}
            size="100%"
            padding="md"
            sx={{
              backgroundColor: 'rgb(241, 241, 241)',
            }}
            title={userDetails.projectConfig.contents.heading}
            className={classes.hiddenDesktop}
            zIndex={1000000}
          >
            <ScrollArea sx={{ height: '100vh' }} mx="-md">
              <Divider my="sm" />

              <Paper p={'1rem'}>
                <Anchor href={NAV.USER_SUBMISSIONS_LIST}>Dashboard</Anchor>

                <Text color="dimmed" size="sm">
                  {localStorage.Get('email')}
                </Text>
              </Paper>

              <Divider my="sm" />

              <Group position="center" grow pb="xl" px="md">
                <Button
                  variant="outline"
                  styles={{
                    outline: { borderColor: 'gray', color: 'dimgray' },
                  }}
                  onClick={handleLogout}
                >
                  Logout
                </Button>
              </Group>
            </ScrollArea>
          </Drawer>
        </>
      )}
    </>
  );
}
