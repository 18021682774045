import { ColumnType } from '../../../pages/form-field/types';
import { InputDatePicker, InputFile, InputNumber, InputSelect, InputText, InputTextArea } from '../form-field-type';

import InputCheckbox from './input-checkbox';
import ExternalLinks from './input-links';
import Subheading from './subheading';
import TextOnly from './text-only';
import { FormFieldProps } from './types';

export default function FormField(props: FormFieldProps) {
  const { columnType } = props;

  switch (columnType) {
    case ColumnType.Text:
      return <InputText {...props} />; //this is for number and text input field
    case ColumnType.Number:
      return <InputNumber {...props} />;
    case ColumnType.File:
      return <InputFile {...props} />;
    case ColumnType.Select:
      return <InputSelect {...props} />;
    case ColumnType.TextArea:
      return <InputTextArea {...props} />;
    case ColumnType.DatePicker:
      return <InputDatePicker {...props} />;
    case ColumnType.Checkbox:
      return <InputCheckbox {...props} />;
    case ColumnType.SubHeading:
      return <Subheading {...props} />;
    case ColumnType.Paragraph:
      return <TextOnly {...props} />;
    case ColumnType.ExternalLinks:
      return <ExternalLinks {...props} />;
    default:
      return <></>;
  }
}
