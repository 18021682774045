interface ISubmissionSummaryType {
  notYetSubmitted: number;
  SubmittedVerifiedCorrect: number;
  SubmittedVerifiedAmendments: number;
}

export const useCalculateSubmissionSummary = (result: any, submissionSummary: any) => {
  let submissionValue: ISubmissionSummaryType = {
    notYetSubmitted: 0,
    SubmittedVerifiedCorrect: 0,
    SubmittedVerifiedAmendments: 0,
  };

  if (result?.data && submissionSummary?.submissionTables?.length > 0) {
    submissionValue = result.data.submissions.reduce((acc: ISubmissionSummaryType, curr: any) => {
      if (!curr?.acknowledgements?.ack_agree) {
        acc.notYetSubmitted += 1;
      } else {
        if (curr?.review_verify?.registration_info?.length) {
          const verified = curr.review_verify.registration_info[0];
          if (verified === 'correct') {
            acc.SubmittedVerifiedCorrect += 1;
          } else {
            acc.SubmittedVerifiedAmendments += 1;
          }
        }
      }

      return acc;
    }, submissionValue);
  }

  return submissionValue;
};
