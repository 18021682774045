import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { showNotification, updateNotification } from '@mantine/notifications';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Alert from '../../../../component/common/alert';
import { Footer } from '../../../../component/Footer';
import { NAV } from '../../../../config/navigation';

import Header from '../../../form-field/component/header';
import { useGetSubmissionForm } from '../../../form-field/hooks';
import { useGetUserSubmission, useUpdateMySubmission } from '../../hooks';
import EditForm from './components/edit-form';
import Content from '../../../../component/common/content';
import Loader from '../../../../component/common/loader';
import { CheckIfSims } from '../../../../utils/helper';

const ViewMySubmission = () => {
  const params = useParams();
  const { data: editData, isFetchedAfterMount } = useGetUserSubmission(params.id || '');
  const processUpdateHook = useUpdateMySubmission(params.id || '');
  const { error, data: initialForm, isLoading } = useGetSubmissionForm();
  const navigate = useNavigate();

  useEffect(() => {
    if (processUpdateHook.isSuccess) {
      updateNotification({
        id: 'load-data',
        color: 'teal',
        title: 'Edit Submission',
        message: 'Submission was updated......',
        icon: <FontAwesomeIcon icon={faCheck} />,
        autoClose: 1500,
        onClose: () => {
          navigate(NAV.USER_SUBMISSIONS_LIST);
        },
      });
    }
  }, [processUpdateHook.isSuccess, navigate]);

  const handleSave = (value: any) => {
    showNotification({
      id: 'load-data',
      loading: true,
      title: 'Updating Submission',
      message: 'Saving.....',
      autoClose: false,
      disallowClose: true,
    });

    processUpdateHook.mutate(value);
  };

  if (error) {
    return <Alert message={error.message} />;
  }

  if (isLoading) {
    return <Loader />;
  }

  const isSims = CheckIfSims();

  return (
    <>
      <div
        style={{
          width: '100%',
          margin: 0,
          display: 'flex',
          flexDirection: 'column',
          ...(!isSims && { minWidth: 'max-content' }),
        }}
      >
        <Header />
        {isSims && <Content />}
        {initialForm && editData && isFetchedAfterMount && (
          <EditForm
            data={initialForm}
            editData={editData}
            onSaveEdit={handleSave}
            loadingSubmission={processUpdateHook.isLoading}
          />
        )}
        <Footer />
      </div>
    </>
  );
};

export default ViewMySubmission;
