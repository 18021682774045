import CoreService from '../../services/core';
import { ICoreServiceProps } from '../../services/types';
import CoreConfig from '../../config/core';
import CatchError from '../../services/error';
import { ICreateUserPayload, IUserPayload } from './types';

class AdminCustomerUserModel extends CoreService {
  constructor(path: string, config: ICoreServiceProps) {
    super(path, config);
    this.path = path;
  }

  async GetCustomerUserTotal() {
    try {
      const result = await this.axios.get(this.path + '/stats');
      return result.data.data;
    } catch (e: any) {
      throw CatchError.Negotiate(e);
    }
  }

  async GetCustomerUsers(params: any) {
    try {
      const result = await this.axios.get(`${this.path}?${new URLSearchParams(params).toString()}`);
      return result.data;
    } catch (e: any) {
      throw CatchError.Negotiate(e);
    }
  }

  async FindCustomerById(id: string) {
    try {
      const result = await this.axios.get(`${this.path}/${id}`);
      return result.data.data;
    } catch (e: any) {
      throw CatchError.Negotiate(e);
    }
  }

  async UpdateCustomerUser(payload: IUserPayload) {
    try {
      const result = await this.axios.put(`${this.path}/${payload.id}`, payload.request);
      return result.data.data;
    } catch (e: any) {
      throw CatchError.Negotiate(e);
    }
  }

  async DeleteCustomerUser(id: string) {
    try {
      const result = await this.axios.delete(`${this.path}/${id}`);
      return result.data.data;
    } catch (e: any) {
      throw CatchError.Negotiate(e);
    }
  }

  async CreateCustomer(customer: ICreateUserPayload) {
    try {
      const result = await this.axios.post(this.path, customer);
      return result.data.data;
    } catch (e: any) {
      throw CatchError.Negotiate(e);
    }
  }

  async VerifyUserAccount(id: string) {
    try {
      const result = await this.axios.patch(`${this.path}/${id}/verify`);
      return result.data.data;
    } catch (e: any) {
      throw CatchError.Negotiate(e);
    }
  }
}
export default new AdminCustomerUserModel('/admin/customer-users', CoreConfig);
