import { Space, Container, Tabs } from '@mantine/core';
import { FileCode2, ColorPicker, Mail, Check, AlertOctagon } from 'tabler-icons-react';
import Alert from '../../component/common/alert';
import OverlayLoader from '../../component/common/loading-overlay';
import { useGetProjectDetails, useUpdateProject } from './hooks';

const ProjectSettings = () => {
  const { data, isLoading, error, isError } = useGetProjectDetails();
  const updateHook = useUpdateProject();
  if (isLoading) {
    return <OverlayLoader visible={isLoading} />;
  }
  if (isError) {
    console.log('error:', error);
    return <Alert message="Error loading submissions list.." />;
  }
  if (!data) {
    return <OverlayLoader visible={isLoading} />;
  }

  return (
    <Container px={0} mb={'5rem'}>
      <Space h={20} />
      {updateHook.isSuccess && (
        <Alert
          color="green"
          icon={<Check size={18} />}
          title="Operation Success!"
          message="Project Settings Updated!"
        />
      )}
      {updateHook.isError && (
        <Alert
          icon={<AlertOctagon size={18} />}
          title="Operation Failed!"
          message="An error occured while updating the project settings. Please try again."
        />
      )}
      <Space h={20} />
      <Tabs grow>
        <Tabs.Tab label="General Settings" icon={<FileCode2 size={20} />}>
          <Alert
            color="teal"
            icon={<AlertOctagon size={18} />}
            title="Feature Available Soon!"
            message="This functionality will be available soon, please check back later."
          />
        </Tabs.Tab>
        <Tabs.Tab label="Mail Settings" icon={<Mail size={20} />} style={{ width: '100%' }}>
          <Alert
            color="teal"
            icon={<AlertOctagon size={18} />}
            title="Feature Available Soon!"
            message="This functionality will be available soon, please check back later."
          />
        </Tabs.Tab>
        <Tabs.Tab label="Theme Settings" icon={<ColorPicker size={20} />}>
          <Alert
            color="teal"
            icon={<AlertOctagon size={18} />}
            title="Feature Available Soon!"
            message="This functionality will be available soon, please check back later."
          />
        </Tabs.Tab>
        {/* <Tabs.Tab label="Submission Settings" icon={<ClipboardText size={20} />}>
          Settings tab content
        </Tabs.Tab> */}
      </Tabs>
    </Container>
  );
};

export default ProjectSettings;
