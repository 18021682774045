import { Button, Paper, Text, Title } from '@mantine/core';
import { useEffect } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import Alert from '../../../component/common/alert';
import Loader from '../../../component/common/loader';
import { useProcessConfirmation } from '../hooks';
import { container_confirmation } from '../styles';
import { NAV } from '../../../config/navigation';
import { useGetProjectDetails } from '../../login/hooks';

export default function Confirmation() {
  const [param] = useSearchParams();
  const code = param.get('code');
  const { mutate, isError, error, isLoading, isSuccess } = useProcessConfirmation();
  const { isLoading: projectLoading, data } = useGetProjectDetails();

  useEffect(() => {
    if (code) {
      mutate({ code });
    }
  }, [code, mutate]);

  if (isError) {
    if (error?.error?.code === 1002) {
      return <Alert message={error?.error.message} code={1002} />;
    }

    return <Alert message={error?.error.message} />;
  }

  if (isLoading || projectLoading) {
    return <Loader />;
  }

  return (
    <div style={container_confirmation}>
      {isSuccess && (
        <Paper
          shadow={'lg'}
          withBorder
          radius={'md'}
          p={'5rem'}
          sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}
        >
          <Title>Thank you</Title>
          <Text>{data?.projectConfig?.messages?.confirm_registration || 'Your subscription has been confirmed.'}</Text>
          <Button component={Link} to={NAV.LOGIN}>
            Go to Sign in
          </Button>
        </Paper>
      )}
    </div>
  );
}
