import { Text } from '@mantine/core';
import { FormFieldProps } from './types';

export default function TextOnly({ hideLabel = false, ...props }: FormFieldProps) {
  return (
    <Text align="justify" style={{ maxWidth: '70rem' }}>
      {props.defaultValue}
    </Text>
  );
}
