export enum ColumnType {
  TextArea = 'textarea',
  Select = 'select',
  File = 'file',
  Text = 'text',
  DatePicker = 'date',
  Number = 'number',
  SubHeading = 'subheading',
  Paragraph = 'paragraph',
  Checkbox = 'checkbox',
  ExternalLinks = 'links',
}

export enum ColumnChildType {
  PROOF_OF_DEBT = 'Proof of Debt Misc',
  CREDITOR_DETAILS = 'Creditor Details',
  CONTACT_DETAILS = 'Contact Details',
  SECURITY_PARTICULARS = 'Security Particulars"',
}
