import { ActionIcon, Button, Container, Group, List, TextInput, Title } from '@mantine/core';
import { useMemo, useState } from 'react';
import { Link as LinkIcon, Trash } from 'tabler-icons-react';
import { FormFieldProps } from './types';
import localStorage from '../../../helpers/local-storage';

export interface ExternalLink {
  label: string;
  url: string;
}

export default function ExternalLinks(props: FormFieldProps) {
  const externalLinksKey = `${props.tableName}.${props.columnName}`;
  const [title, setTitle] = useState<string>();
  const [url, setUrl] = useState<string>();
  const isAdmin = localStorage.IsAdmin();

  let externalLinks: ExternalLink[] = [];
  if (props.form.getInputProps(externalLinksKey).value) {
    const parsedLinks = JSON.parse(props.form.getInputProps(externalLinksKey).value);
    externalLinks = parsedLinks.links;
  }
  const [links, setLinks] = useState<ExternalLink[]>(externalLinks);

  const onAddLink = () => {
    if (!title || !url) {
      return;
    }
    const updateLinks = [...links, { label: title, url: url }];

    const data = {
      links: updateLinks,
    };

    const fieldValues = props.form.values;
    const updatedValues = {
      ...fieldValues,
      [props.tableName]: {
        ...fieldValues[props.tableName],
        external_links: JSON.stringify(data),
      },
    };
    props.form.setValues(updatedValues);
    setLinks(updateLinks);
    setTitle('');
    setUrl('');
  };

  const onRemoveLink = (link: ExternalLink) => {
    const newLinks = links.filter(l => l.label !== link.label && l.url !== link.url);

    const data = {
      links: newLinks,
    };
    setLinks(newLinks);
    const fieldValues = props.form.values;
    const updatedValues = {
      ...fieldValues,
      [props.tableName]: {
        ...fieldValues[props.tableName],
        external_links: JSON.stringify(data),
      },
    };
    props.form.setValues(updatedValues);
  };

  const isDisabled = useMemo(() => {
    return !title || !url;
  }, [title, url]);

  return (
    <Container
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
      }}
    >
      <Title order={6}>External Links</Title>
      <List>
        {links.map(l => (
          <List.Item sx={{ margin: '0.5rem' }} key={JSON.stringify(l)}>
            <Group>
              <Button
                leftIcon={<LinkIcon />}
                variant="outline"
                rel="noopener noreferrer"
                component="a"
                href={l.url}
                sx={{ flexGrow: 1 }}
                target="_blank"
              >
                {l.label}
              </Button>
              {isAdmin && (
                <ActionIcon variant="outline" onClick={() => onRemoveLink(l)} color="gray">
                  <Trash />
                </ActionIcon>
              )}
            </Group>
          </List.Item>
        ))}
      </List>
      {isAdmin && (
        <>
          <Group grow>
            <TextInput placeholder="Title" value={title} onChange={e => setTitle(e.target.value)} />
            <TextInput placeholder="Link" value={url} onChange={e => setUrl(e.target.value)} />
          </Group>
          <Button type="button" onClick={() => onAddLink()} disabled={isDisabled}>
            Add Link
          </Button>
        </>
      )}
    </Container>
  );
}
