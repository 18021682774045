import { useForm, yupResolver } from '@mantine/form';
import { useMutation, useQuery } from 'react-query';
import { IErrorObject } from '../../services/types';
import { IProject } from '../project/types';
import { IUserAdminProfile, IUserProfile } from '../user/types';

//Customer Model
import AuthModel from './auth/models/auth.model';
import RootModel from './auth/models/root.model';

//Admin Model

import { formFieldsSchema } from './schema';
import { ILoginFormProps } from './types';

//--- actual hook
/**
 *
 * @todo store project details on browser local storage to minimize uncessary API calls
 */
const useGetProjectDetails = () => {
  return useQuery<IProject, any>(['rootProjectDetails'], () => {
    return RootModel.GetProjectDetails();
  });
};

const useProcessLogin = () => {
  return useMutation<IUserProfile | IUserAdminProfile, IErrorObject, ILoginFormProps, IUserProfile | IUserAdminProfile>(
    async ({ user, password }: ILoginFormProps) => {
      return await AuthModel.Authenticate(user, password);
    },
    {},
  );
};
const useProcessLogout = () => {
  return useMutation<void, IErrorObject, void, void>(async () => {
    return await AuthModel.Logout();
  }, {});
};

const useLoginForm = () => {
  return useForm({
    initialValues: {
      user: '',
      password: '',
    },
    validate: yupResolver(formFieldsSchema),
  });
};

export { useLoginForm, useProcessLogin, useProcessLogout, useGetProjectDetails };
