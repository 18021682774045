import { useParams } from 'react-router-dom';
import { CustomPage } from './custom-page';

interface ProjectPageProps {
  forcePath?: string;
}

export const ProjectPage = ({ forcePath }: ProjectPageProps) => {
  const params = useParams();

  const path = forcePath || params.path || 'index';

  return <CustomPage path={path} />;
};
