import { Anchor } from '@mantine/core';
import { useMemo } from 'react';

const View = ({ fileId }: { fileId: any }) => {
  const url = useMemo(() => {
    if (fileId.data) {
      const arr = new Uint8Array(fileId.data);
      const blob = new Blob([arr], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      return url;
    }
  }, [fileId]);

  return url ? (
    <Anchor href={url} target="_blank">
      View
    </Anchor>
  ) : (
    <></>
  );
};

export default View;
