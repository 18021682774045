export const CheckIfSims = () => {
  return (
    window.location.origin.toLowerCase().includes('sims') ||
    window.location.origin.toLowerCase().includes('localhost:5003')
  );
};

export const BANK_DETAILS = 'Bank Details';

export const DISTRIBUTION_NOTICE = ['final_distribution_notice', 'estimated_distribution_notice'];

// reference
// https://stackoverflow.com/questions/64781995/how-to-get-mime-type-of-an-array-buffer-object
// https://medium.com/the-everyday-developer/detect-file-mime-type-using-magic-numbers-and-javascript-16bc513d4e1e
export const getMimeType = (arrayBuffer: any) => {
  const uint8arr = new Uint8Array(arrayBuffer);

  const len = 4;
  if (uint8arr.length >= len) {
    let signatureArr = new Array(len);
    for (let i = 0; i < len; i++)
      signatureArr[i] = signatureArr[i] = new Uint8Array(arrayBuffer)[i].toString(16).padStart(2, '0');
    const signature = signatureArr.join('').toUpperCase();
    switch (signature) {
      case '89504E47':
        return 'image/png';
      case '47494638':
        return 'image/gif';
      case '25504446':
        return 'application/pdf';
      case 'FFD8FFDB':
      case 'FFD8FFE0':
        return 'image/jpeg';
      default:
        return 'image/png';
    }
  }
  return 'application/pdf';
};
