import React from 'react';

const alignCenter: React.CSSProperties = {
  display: 'flex',
  alignItems: 'center',
};

const headerStyle: React.CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '1rem',
  backgroundColor: '#f1f1f1',
};

export { alignCenter, headerStyle };
