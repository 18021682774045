import CoreService from '../../../services/core';
import {ICoreServiceProps, ICoreServiceResponse} from '../../../services/types';
import CatchError from '../../../services/error';
import CoreConfig from '../../../config/core';

class PagesModel extends CoreService {
  constructor(path: string, config: ICoreServiceProps) {
    super(path, config);
    this.path = path;
  }

  async getPage(path: string) {
    try {
      const result: ICoreServiceResponse = await this.axios.get(`${this.path}/${path}`);
      return result.data.data;
    }
    catch (e) {
      throw CatchError.Negotiate(e);
    }
  }
}

export default new PagesModel('/projects/page', CoreConfig);
