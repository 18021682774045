import CoreService from '../../services/core';
import { ICoreServiceProps, ICoreServiceResponse } from '../../services/types';
import CoreConfig from '../../config/core';
import CatchError from '../../services/error';
import { IProject } from '../../pages/project/types';

class AdminProjectModel extends CoreService {
  constructor(path: string, config: ICoreServiceProps) {
    super(path, config);
    this.path = path;
  }

  async GetProjectDetails() {
    try {
      const result: ICoreServiceResponse = await this.axios.get(`${this.path}/default`);
      return result.data.data;
    } catch (e: any) {
      throw CatchError.Negotiate(e);
    }
  }

  async UpdateProjectDetails(project: IProject) {
    try {
      const result: ICoreServiceResponse = await this.axios.patch(`${this.path}/default`, project);
      return result.data;
    } catch (e: any) {
      throw CatchError.Negotiate(e);
    }
  }
}
export default new AdminProjectModel('/admin/projects', CoreConfig);
