import CoreConfig from "../../../config/core";
import CoreService from "../../../services/core";
import CatchError from "../../../services/error";
import { ICoreServiceProps } from "../../../services/types";

class ResetPasswordModel extends CoreService {
   constructor(path: string, config: ICoreServiceProps) {
      super(path, config);
      this.path = path;
   }

   async GetPasswordResetLink({ email }: { email: string }) {
      try {
         await this.axios.post(`${this.path}/reset-password`, { email });
      } catch (e: any) {
         throw CatchError.Negotiate(e);
      }
   }
}

export default new ResetPasswordModel(`/customer-users`, CoreConfig);
