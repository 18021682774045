import { Button, Group } from '@mantine/core';

import { CheckIfSims } from '../../../../../utils/helper';
import FormProjectTable from '../../../../form-field/component/project-table';
import FormProjectTableColumn from '../../../../form-field/component/project-table-columsn';
import TableRepeater from '../../../../form-field/component/table-repeater';
import { IProjectTableWithChild, IProjectTableWithColumns } from '../../../../project/types';
import { useFormEditHook } from '../../../hooks';
import { IInsolvencyUserSubmission } from '../../../types';
import { useMemo } from 'react';
import localStorage from '../../../../../helpers/local-storage';

interface MainProps {
  data: IProjectTableWithChild;
  editData: IInsolvencyUserSubmission;
  onSaveEdit: (values: any) => void;
  loadingSubmission?: boolean;
}

export default function EditForm({ data, editData, onSaveEdit, loadingSubmission = false }: MainProps) {
  const form = useFormEditHook(data, editData);
  const isAdmin = localStorage.IsAdmin();
  const isSims = CheckIfSims();
  const foreignChildItems = data?.child?.reduce<{ [key: string]: IProjectTableWithColumns[] }>((acc, item) => {
    if (item.tableConfig?.displayFor) {
      const displayFor = item.tableConfig.displayFor;
      return { ...acc, [displayFor]: [...(acc[displayFor] || []), item] };
    }

    return acc;
  }, {});

  const disasbleUpdateSims = useMemo(() => {
    if (!isSims || isAdmin) {
      return false;
    }

    return true;
  }, [isSims]);

  return (
    <>
      <form onSubmit={form.onSubmit(onSaveEdit)}>
        {data.child
          ?.filter(table => !table.tableConfig?.displayFor)
          ?.map(table => {
            const { tableConfig, projectTableId } = table;

            const foreignChildren = (foreignChildItems[projectTableId] || []).map(item => (
              <div key={item.projectTableId}>
                {!item.tableConfig?.isTableDisplay ? (
                  <FormProjectTableColumn
                    tableConfig={tableConfig}
                    form={form}
                    tableName={item.tableName}
                    columns={item.columns}
                  />
                ) : (
                  <TableRepeater
                    tableConfig={tableConfig}
                    key={`${item.projectTableId}`}
                    form={form}
                    table={item}
                    tableName={item.tableName}
                  />
                )}
              </div>
            ));

            return (
              <FormProjectTable
                isSubTable={tableConfig?.isSubTable ?? false}
                tableName={table.tableName}
                description={table.description}
                key={table.projectTableId}
                tableConfig={tableConfig}
              >
                {foreignChildren}

                {!tableConfig?.isTableDisplay ? (
                  <FormProjectTableColumn
                    tableConfig={tableConfig}
                    form={form}
                    tableName={table.tableName}
                    columns={table.columns}
                  />
                ) : (
                  <TableRepeater
                    tableConfig={tableConfig}
                    key={`${table.projectTableId}`}
                    form={form}
                    table={table}
                    tableName={table.tableName}
                  />
                )}
              </FormProjectTable>
            );
          })}

        <hr style={{ marginTop: '10rem', marginLeft: '1.25rem', marginRight: '1.25rem' }} />
        <Group position="center" direction="column" mb={'xl'}>
          <Button
            type="submit"
            color={'dark'}
            size="lg"
            m={'lg'}
            loading={loadingSubmission}
            disabled={disasbleUpdateSims}
          >
            {isSims ? 'Submit' : 'Save'}
          </Button>
        </Group>
      </form>
    </>
  );
}
