import { AppShell, Container, Text } from '@mantine/core';
import { Outlet } from 'react-router-dom';
import { Footer } from '../component/Footer';

interface LayoutProps {
  header?: React.ReactNode;
}

export const Layout = ({ header }: LayoutProps) => {
  return (
    <AppShell
      styles={{
        root: {
          display: 'flex',
          flexDirection: 'column',
          height: '100vh',
        },

        body: {
          flex: 1,
          display: 'flex',
        },
      }}
      header={
        <div
          style={{
            width: '100%',
            margin: 0,
            display: 'flex',
            flexDirection: 'column',
            minWidth: 'max-content',
          }}
        >
          {header ?? <Text align="center">App Header</Text>}
        </div>
      }
      footer={<Footer />}
    >
      <Container fluid px={0}>
        <Outlet />
      </Container>
    </AppShell>
  );
};
