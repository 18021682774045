import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Container, Group, ScrollArea, Space, Title } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { showNotification, updateNotification } from '@mantine/notifications';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { Download, Upload } from 'tabler-icons-react';
import Alert from '../../../component/common/alert';
import OverlayLoader from '../../../component/common/loading-overlay';
import ZipZipPagination from '../../../component/common/pagination';
import AdminSubmissionsModel from '../AdminSubmissions.model';
import { useGetSubmissions, useImportRgmZip, useImportScopeSubmissions, useImportSubmissions } from '../hook';
import FilterBox from './Filter';
import { GetTableHeaders } from './handlers';
import Summary from './submission-summary';
import SubmissionsListTable from './table';
import SubmissionListThead from './table/thead';
import SubmissionListData from './table/trows';

const SubmissionsList = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState<{ daterange: Date[]; search: string }>();
  const { data: result, isLoading, isError } = useGetSubmissions(currentPage, filters);
  const importSubmissions = useImportSubmissions();
  const importScopeSubmissions = useImportScopeSubmissions();
  const importZipRgmMutation = useImportRgmZip();
  const inputFileImport = useRef(null);
  const inputFileImportScope = useRef(null);
  const inputFileZip = useRef(null);
  const largeScreen = useMediaQuery('(min-width: 900px)');

  useEffect(() => {
    if (importSubmissions.isSuccess || importScopeSubmissions.isSuccess || importZipRgmMutation.isSuccess) {
      updateNotification({
        id: 'import',
        color: 'teal',
        title: 'Import records',
        message: 'Imported',
        icon: <FontAwesomeIcon icon={faCheck} />,
        autoClose: 1500,
      });
    }
  }, [importSubmissions.isSuccess, importScopeSubmissions.isSuccess, importZipRgmMutation.isSuccess]);

  const onImportClick = () => {
    // @ts-ignore
    inputFileImport.current?.click();
  };

  const onImportScopeClick = () => {
    // @ts-ignore
    inputFileImportScope.current?.click();
  };

  const onImportZipClick = () => {
    // @ts-ignore
    inputFileZip.current?.click();
  };

  const onFileImportChange = (event: ChangeEvent<HTMLInputElement>) => {
    showNotification({
      id: 'import',
      loading: true,
      title: 'Import records',
      message: 'Saving.....',
      autoClose: false,
      disallowClose: true,
    });

    importSubmissions.mutate(event.target?.files?.[0]);
  };

  const onFileImportScopeChange = (event: ChangeEvent<HTMLInputElement>) => {
    showNotification({
      id: 'import',
      loading: true,
      title: 'Import records',
      message: 'Saving.....',
      autoClose: false,
      disallowClose: true,
    });

    importScopeSubmissions.mutate(event.target?.files?.[0]);
  };

  const onFileImportZipChange = (event: ChangeEvent<HTMLInputElement>) => {
    showNotification({
      id: 'import',
      loading: true,
      title: 'Import records',
      message: 'Saving.....',
      autoClose: false,
      disallowClose: true,
    });
    importZipRgmMutation.mutate(event.target?.files?.[0]);
  };

  if (isLoading) {
    return <OverlayLoader visible={isLoading} />;
  }
  if (isError) {
    return <Alert message="Error loading submissions list.." />;
  }
  if (!result) {
    return <OverlayLoader visible={isLoading} />;
  }

  const { submissions = [], tables } = result?.data;
  const { child } = tables;

  const tableHeader = GetTableHeaders(child);

  return (
    <Container fluid px={0}>
      <Summary result={result} />
      <ScrollArea py={'lg'} px={largeScreen ? 'lg' : 0}>
        <FilterBox handler={setFilters} />
        <Space h={20} />
        <Group grow>
          <Title order={3} pb={'0.5rem'} align="justify">
            Submissions
          </Title>
        </Group>
        <SubmissionsListTable>
          {tables && <SubmissionListThead headers={tableHeader} />}
          {submissions && <SubmissionListData submissions={submissions} headers={tableHeader} />}
        </SubmissionsListTable>
        <Space h={20} />
        <ZipZipPagination pagination={result?.meta?.pagination} changeHandler={setCurrentPage} />
        <Space h={20} />

        <Group>
          <form method="post" action={AdminSubmissionsModel.GetExportUrl()}>
            <Button leftIcon={<Download />} type="submit">
              Export
            </Button>
          </form>
          <form method="post" action={AdminSubmissionsModel.GetExportScopeUrl('trade_data')}>
            <Button leftIcon={<Download />} type="submit">
              Export Transactions
            </Button>
          </form>

          <div>
            <Button leftIcon={<Upload />} onClick={onImportClick} type={'button'}>
              Import
            </Button>

            <input
              type="file"
              accept="text/csv"
              id="file"
              ref={inputFileImport}
              style={{ display: 'none' }}
              onChange={onFileImportChange}
            />
          </div>

          <div>
            <Button leftIcon={<Upload />} onClick={onImportScopeClick} type={'button'}>
              Import Transactions
            </Button>

            <input
              type="file"
              accept="text/csv"
              id="file"
              ref={inputFileImportScope}
              style={{ display: 'none' }}
              onChange={onFileImportScopeChange}
            />
          </div>

          <div>
            <Button leftIcon={<Upload />} onClick={onImportZipClick} type={'button'}>
              Import RGM Zip
            </Button>

            <input
              type="file"
              accept=".zip"
              id="file"
              ref={inputFileZip}
              style={{ display: 'none' }}
              onChange={onFileImportZipChange}
            />
          </div>
        </Group>
      </ScrollArea>
    </Container>
  );
};

export default SubmissionsList;
