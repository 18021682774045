import { useEffect, useState } from 'react';
import AdminSubmissionsModel from '../admin/submissions/AdminSubmissions.model';
import SubmissionModel from '../pages/submission/submission.model';
import { getMimeType } from '../utils/helper';
import localStorage from '../helpers/local-storage';

export type ErrorResponse = {
  error: {
    message: string;
    status: number;
    name: string;
  };
  meta?: string;
};

export const useGetRgmDoc = (submissionId: string) => {
  const [isLoading, setIsLoading] = useState(false);
  const [fileName, setFileName] = useState('');
  const [previewUrl, setPreviewUrl] = useState('');
  const [error, setError] = useState<ErrorResponse | null>(null);
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const isAdmin = localStorage.IsAdmin();

  useEffect(() => {
    setIsLoading(true);
    setIsError(false);
    setIsSuccess(false);

    let request;
    if (isAdmin) {
      request = AdminSubmissionsModel.GetSubmission(submissionId);
    } else {
      request = SubmissionModel.GetUserSubmission(submissionId);
    }

    request
      .then(response => {
        let rgmId = response?.rgm_details?.rgm_id || null;

        if (rgmId) {
          rgmId = rgmId.replace(/\s+/g, '');
          AdminSubmissionsModel.GetRgmFile(rgmId)
            .then(response => {
              const fileBlob = new Uint8Array(response.fileBlob.data);

              const mimeType = getMimeType(fileBlob);

              const blob = new Blob([fileBlob], { type: mimeType });
              setPreviewUrl(window.URL.createObjectURL(blob));
              setFileName(response.fileName);
              setIsLoading(false);
              setIsSuccess(true);
            })
            .catch((error: ErrorResponse) => {
              setError(error);
              setIsError(true);
              setIsLoading(false);
            });
        }
      })
      .catch((error: ErrorResponse) => {
        setError(error);
        setIsError(true);
        setIsLoading(false);
      });
  }, []);

  return {
    isLoading,
    fileName,
    previewUrl,
    error,
    isError,
    isSuccess,
  };
};

export const useGetFinalRgmDoc = (submissionId: string) => {
  const [isLoading, setIsLoading] = useState(false);
  const [fileName, setFileName] = useState('');
  const [previewUrl, setPreviewUrl] = useState('');
  const [error, setError] = useState<ErrorResponse | null>(null);
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const isAdmin = localStorage.IsAdmin();

  useEffect(() => {
    setIsLoading(true);
    setIsError(false);
    setIsSuccess(false);

    let request;
    if (isAdmin) {
      request = AdminSubmissionsModel.GetSubmission(submissionId);
    } else {
      request = SubmissionModel.GetUserSubmission(submissionId);
    }

    request
      .then(response => {
        let rgmId = response?.rgm_details?.rgm_id || null;

        if (rgmId) {
          rgmId = rgmId.replace(/\s+/g, '');
          AdminSubmissionsModel.GetFinalNoticeRgmFile(rgmId)
            .then(response => {
              const fileBlob = new Uint8Array(response.fileBlob.data);

              const mimeType = getMimeType(fileBlob);

              const blob = new Blob([fileBlob], { type: mimeType });
              setPreviewUrl(window.URL.createObjectURL(blob));
              setFileName(response.fileName);
              setIsLoading(false);
              setIsSuccess(true);
            })
            .catch((error: ErrorResponse) => {
              setError(error);
              setIsError(true);
              setIsLoading(false);
            });
        }
      })
      .catch((error: ErrorResponse) => {
        setError(error);
        setIsError(true);
        setIsLoading(false);
      });
  }, []);

  return {
    isLoading,
    fileName,
    previewUrl,
    error,
    isError,
    isSuccess,
  };
};
