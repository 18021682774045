import { useForm, yupResolver } from '@mantine/form';
import { randomId } from '@mantine/hooks';
import { useMutation, useQuery } from 'react-query';
import * as Yup from 'yup';
import { IProjectTableWithChild } from '../project/types';
import SubmissionModel from '../submission/submission.model';
import { useYupSchema } from './schema';
const useGetSubmissionForm = () => {
  return useQuery<IProjectTableWithChild, any>(['projectSubmissionForm'], () => {
    return SubmissionModel.GetProjectSubmissionForm();
  });
};

export const useSortedData = (dataItem: IProjectTableWithChild) =>
  dataItem.child.reduce((acc, childItem) => {
    if (childItem.tableConfig?.isTableDisplay) {
      return {
        ...acc,
        [childItem.tableName]: [
          childItem.columns.reduce((childAcc, childColumn) => {
            return {
              ...childAcc,
              [childColumn.columnName]: '',
              key: randomId(),
            };
          }, {}),
        ],
      };
    }

    return {
      ...acc,
      [childItem.tableName]: childItem.columns.reduce((childAcc, childColumn) => {
        return {
          ...childAcc,
          [childColumn.columnName]: '',
        };
      }, {}),
    };
  }, {});
const useFormHook = (data: IProjectTableWithChild, initialValues: object) => {
  const schemaNew = Yup.object().shape(useYupSchema(data));

  return useForm<typeof initialValues>({
    initialValues,
    validate: yupResolver(schemaNew),
  });
};
const useProcessSubmission = () => {
  return useMutation(async (formDetails: unknown) => {
    return await SubmissionModel.SaveSubmission(formDetails);
  }, {});
};

export { useGetSubmissionForm, useFormHook, useProcessSubmission };
